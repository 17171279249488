import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch"; 
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; 

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox"; 
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData"; 
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Swal from "sweetalert2";
const CustomFiles = (props) => {

    
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [dataa, setDataa] = useState([]);
    const [dachansicht, setDachansicht] = useState([]);
    const [ref, setRef] = useState(0);

    const showAlert = (id) => {
        const newSwal = Swal.mixin({
          customClass: {
            confirmButton: "button button-success",
            cancelButton: "button button-error",
          },
          buttonsStyling: false,
        });
    
        newSwal
          .fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
                callFetch("delete-custom-uploaded-file/" + id, "GET", []).then((res) => {  
                    setRef(ref+1); 
                }); 

                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
          });
      }; 
    const removeAttachment = (id) => {
        showAlert(id);
    }  
    
    useEffect(() => { 
        callFetch("get-custom-uploaded-files/" + props.project_id+'?for='+(props?.for ? props?.for : ''), "GET", []).then((res) => { 
            // setDachansicht(res.order.dachansicht  && res.order.dachansicht != null && res.order.dachansicht != 'null' ? JSON.parse(res.order.dachansicht) : null);
            setDataa(res.order);
            setData(res.data); 
        });
    }, [props.project_id, props.refresh, ref]); 
  return (
    <>
        <div className="row mb-5">
            <SoftBox p={3} className="order-processing">
                <Grid container spacing={3}>
                {data?
                    data?.map((item, index) => (  
                        <Grid item key={index}>
                            <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+item.file_path}>
                                <img src={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+item.file_path} />
                            </a>
                            <br/>
                            <a onClick={() => { removeAttachment(item.id) }} className="d-block text-center mt-2">{t('Remove')}</a>
                        </Grid>  
                    )) : <></>}  
                </Grid>
            </SoftBox>
        </div>
        <ToastContainer />
    </>
  )
}

export default CustomFiles