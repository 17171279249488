import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function Reklamation(props) {
    const { t } = useTranslation();


    return (
        <>
            <div className="timeline-block mb-3">
                <span className="timeline-step">
                    <i className="ni ni-bell-55 text-success text-gradient" />
                </span>
                <div className="timeline-content">
                    <h6 className="text-dark text-sm font-weight-bold mb-0">{'Reklamation '} <span style={{ fontWeight: '300', fontStyle: 'italic' }}>{(props?.reclamation?.ticket?.assignorderprocessing_id ? 'DC-' + props?.reclamation?.ticket?.assignorderprocessing_id : '')}</span></h6>
                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(props?.reclamation?.created_at, "dd.mm.yyyy")}</p>
                    <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(props?.reclamation?.created_at, "HH:ss")}</p>

                    <p className="text-sm m-0">
                        <SoftTypography variant="caption"><strong>{t('Rekla.Nr')}: </strong></SoftTypography>
                        <SoftTypography variant="caption"><NavLink to={'/reclamation/' + props?.reclamation?.id}>{'R-' + props?.reclamation?.id}</NavLink></SoftTypography>
                    </p>

                    <p className="text-sm m-0 mb-0">
                        <SoftTypography variant="caption"><strong>{t('Abteilung')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{props?.reclamation?.department}</SoftTypography>
                    </p>

                    <p className="text-sm m-0 mb-0">
                        <SoftTypography variant="caption"><strong>{t('Prioritat')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{props?.reclamation?.priority}</SoftTypography>
                    </p>

                    <p className="text-sm m-0 mb-0">
                        <SoftTypography variant="caption"><strong>{t('Erstellt von')}: </strong></SoftTypography>
                        <SoftTypography variant="caption">{props?.reclamation?.user?.name}</SoftTypography>
                    </p>

                    {props?.reclamation?.department == 'Dachmontage' || props?.reclamation?.department == 'Elektromontage' ? (
                        <p className="text-sm m-0 mb-0">
                            <SoftTypography variant="caption"><strong>{t('Leitstand')}: </strong></SoftTypography>
                            <SoftTypography variant="caption">{props?.reclamation?.dc_freigeben == 1 ? t('DC Approved') : props?.reclamation?.ac_freigeben == 1 ? t('AC Approved') : '--'}</SoftTypography>
                        </p>
                    ) : ''}


                    {props?.reclamation?.reclamation_plan && props?.reclamation?.reclamation_plan.length ? props?.reclamation?.reclamation_plan.map((reclamationplan) => (
                        <>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Technician')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{reclamationplan?.employee?.name ? reclamationplan?.employee?.name : '--'}</SoftTypography>
                            </p>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Panung Datum')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{reclamationplan?.date ? dateFormat(reclamationplan.date, "dd.mm.yyyy") : '--'}</SoftTypography>
                            </p>
                        </>
                    )) : props?.reclamation?.department == 'Dachmontage' || props?.reclamation?.department == 'Elektromontage' ? (
                        <>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Technician')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{'--'}</SoftTypography>
                            </p>
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Panung Datum')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{'--'}</SoftTypography>
                            </p>
                        </>
                    ) : ''}

                    {props?.reclamation?.products && JSON.parse(props?.reclamation?.products).map(function (product, i) {
                        return (
                            <p className="text-sm m-0 mb-0">
                                <SoftTypography variant="caption"><strong>{t('Product')}: </strong></SoftTypography>
                                <SoftTypography variant="caption">{product?.quantity} Stück. {product?.title}</SoftTypography>
                            </p>

                        );
                    })}


                    {props?.reclamation?.department == 'Dachmontage' ? (
                        <p className="text-sm mb-1">
                            <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography>
                            <SoftTypography variant="caption" color={props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status == 1 ? 'success' : ''}>{props?.reclamation?.dc_reclamation_abnahmeprotokoll?.comisioning_status == 1 ? t('Erfolgreich') : t('Nicht Erfolgreich')}</SoftTypography>
                        </p>
                    ) : props?.reclamation?.department == 'Elektromontage' ? (
                        <p className="text-sm mb-1">
                            <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography>
                            <SoftTypography variant="caption" color={props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status == 1 ? 'success' : ''}>{props?.reclamation?.ac_reclamation_abnahmeprotokoll?.comisioning_status  == 1 ? t('Erfolgreich') : t('Nicht Erfolgreich')}</SoftTypography>
                        </p>
                    ) : ''}


                    {props?.reclamation?.reclamation_plan && props?.reclamation?.reclamation_plan.map((reclamationplan) => (
                        <>
                            {reclamationplan.email_trans && reclamationplan.email_trans.map((email_tran) => (
                                <>

                                    {email_tran?.email && email_tran?.email.split(',').map((email) => (
                                        <>
                                            {email ? (
                                                <>
                                                    <p className="text-sm m-0 mb-1">
                                                        <SoftTypography variant="caption"><strong>{t('E-Mail')}: </strong></SoftTypography>
                                                        <SoftTypography variant="caption">{dateFormat(email_tran.created_at, "HH:ss, dd.mm.yyyy")}</SoftTypography>
                                                    </p>
                                                    <p className="text-sm m-0 mb-1">
                                                        <SoftTypography variant="caption">{email}</SoftTypography>
                                                    </p>
                                                </>
                                            ) : ''}

                                        </>
                                    ))}
                                </>
                            ))}
                        </>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Reklamation;
