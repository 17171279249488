import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useLocation, useParams } from "react-router-dom";
import CallCenter from "./create/CallCenter";
import Distribution from "./create/Distribution";
import OrderProcessing from "./create/OrderProcessing";
import Order from "./create/Distribution/Order";
import OrderSign from "./create/Distribution/OrderSign";
import Invoices from "./create/Distribution/Invoices";
import Files from "./create/Files";
import NavMenu from "./Layouts/NavMenu";
import Cookies from 'js-cookie';
import ProjectStatus from "./OrderProccessing/ProjectStatus";
import RoofPlannerV2 from "./RoofPlannerV2";
import RoofPlannerV3 from "./RoofPlannerV3";
import CorrectionRequest from "./create/CorrectionRequest";
import ReclamationCreate from "../reclamation/Create";
import TicketCreate from "../ticket/Create";
import Fertigmeldung from "./create/Fertigmeldung";
import Services from "./Services/Index";
import DistributionSign from "./create/Distribution/DistributionSign";
import Materialliste from './materialliste/Index';
function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('callcenter');
    const [refresh, setRefresh] = useState(1);
    const location = useLocation();

    const activeDataTab = (data) => {
        setActiveTab(data);
    }

    useEffect(() => {

        if (location?.hash) {
            setActiveTab(location?.hash.replace("#", ""));
            return;
        }


        if (JSON.parse(Cookies.get('permissions')).indexOf("PCCR") !== -1) {
            setActiveTab('callcenter');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("PDR") !== -1) {
            setActiveTab('distribution');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("OPR") !== -1) {
            setActiveTab('auftrag');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("POPR") !== -1) {
            setActiveTab('order_processing');
            document.getElementById('order_processing').click();
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("PFR") !== -1) {
            setActiveTab('files');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("PNR") !== -1) {
            setActiveTab('notes');
        } else if (JSON.parse(Cookies.get('permissions')).indexOf("PAR") !== -1) {
            setActiveTab('accounting');
        }
    }, [params.id, location?.hash]);

    return (
        <>
            <div className="container-fluid" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <div className="mb-3">
                    <NavMenu activeTab={activeDataTab} currentActiveTab={activeTab} />
                </div>

                {activeTab == 'callcenter' ? (
                    <CallCenter refresh={refresh} update={true} />
                ) : activeTab == 'distribution' ? (
                    <Distribution refresh={refresh} />
                ) : activeTab == 'auftrag' ? (
                    <Order refresh={refresh} />
                ) : activeTab == 'materialliste' ? (
                    <Materialliste refresh={refresh} projectid={params.id} />)
                    : activeTab == 'roof-planner' ? (
                        <>
                            {/*<RoofPlanner refresh={refresh} projectid={params.id} />*/}
                            {/*<RoofPlannerV2 refresh={refresh} />*/}
                            <RoofPlannerV3 refresh={refresh} />
                        </>
                    ) : activeTab == 'order_processing' ? (
                        <OrderProcessing refresh={refresh} />
                    ) : activeTab == 'digitalsign' ? (
                        <OrderSign refresh={refresh} />
                    ) : activeTab == 'digitalSignQuation' ? (
                        <DistributionSign refresh={refresh} />
                    ) : activeTab == 'files' ? (
                        <Files refresh={refresh} projectid={params.id} />
                    ) : activeTab == 'service' ? (
                        <Services refresh={refresh} projectid={params.id} />
                    )
                        : activeTab == 'accounting' ? (
                            <Invoices refresh={refresh} />
                        ) : activeTab == 'notes' ? (
                            <ProjectStatus refresh={refresh} projectid={params.id} />
                        ) : activeTab == 'fertigmeldung_versenden' ? (
                            <Fertigmeldung refresh={refresh} />
                        ) : <></>}
            </div>

            <CorrectionRequest related={activeTab} refresh={refresh} setRefresh={setRefresh} />

            {JSON.parse(Cookies.get('permissions')).indexOf("RecC") !== -1 ? (
                <ReclamationCreate setrefresh={setRefresh} projectid={params?.id} />
            ) : ''}

            {JSON.parse(Cookies.get('permissions')).indexOf("TicC") !== -1 ? (
                <TicketCreate setrefresh={setRefresh} projectid={params?.id} />
            ) : ''}
        </>
    )
}

export default Edit;
