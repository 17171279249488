import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import IndexTableSalesPersion from "./IndexTableSalesPersion";
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx'; 

function Index() {
    const { t } = useTranslation();
    let { type } = useParams();
    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                {JSON.parse(Cookies.get('permissions')).indexOf("EC") !== -1 ? (
                    <NavLink to="/teams/create" className="btn btn-icon btn-primary">
                        {t('Add Team')}
                    </NavLink>
                ) : <></>}
                    <div className=" d-inline-block pull-right">
                        {JSON.parse(Cookies.get('permissions')).indexOf("EC") !== -1 ? (
                            <button className="btn btn-outline-dark me-2" type="button" data-bs-toggle="modal" data-bs-target="#importModal" >{t('Import CSV')}</button>
                        ) : <></>}
                        {JSON.parse(Cookies.get('permissions')).indexOf("EC") !== -1 ? (
                            <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'employee-export'} className="btn btn-outline-dark">
                                {t('Export CSV')}
                            </a>
                        ) : <></>}
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Teams')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            {type == 'sales' ? (
                                <IndexTableSalesPersion type={type}/>
                            ) : (
                                <IndexTable type={type}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <ImportModal/> */}
        </>
    );
}

export default Index;
