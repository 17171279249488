import React from "react";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import callFetch from "../../helpers/callFetch";
import Cookies from 'js-cookie';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftDropzone from "components/SoftDropzone";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import dateFormat from "dateformat";
import Grid from "@mui/material/Grid";

function Create(props) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reclamation, setReclamation] = useState('Projektbezogene');
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dateFormat(new Date(), "yyyy-mm-dd"),);
    const [endDate, setEndDate] = useState('');
    const notify = () => toast("Success");
    const [attachments, setAttachment] = useState([]);
    const [editForm, setEditForm] = useState(false);
    const [zuweisenAnPerson, setZuweisenAnPerson] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState({ id: '' });
    const [planIds, setPlanIds] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const handleProject = (value) => {
        setData([])
        if (value) {
            callFetch("project/timelines/" + value, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const handleCheckboxChange = (event) => {
        if (event.target.checked === false) {
            setReclamation('Projektbezogene');
        } else {
            setReclamation('Allgemeine');
        }
    };

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }

    const handleCheckboxChangeProjekt = (event) => {
        if (event.target.checked === false) {
            setReclamation('Allgemeine');
        } else {
            setReclamation('Projektbezogene');
        }
    }

    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    }


    const onSubmit = (formData) => {
        formData.zuweisen_an_person = (formData.zuweisen_an_person ? 1 : 0);
        formData.attachments = JSON.stringify(attachments);

        if (reclamation === 'Allgemeine') {
            formData.project_id = 0;
        }

        setSaving(true);
        callFetch("ticket", "POST", formData, setError).then((res) => {
            setSaving(false);
            props?.setrefresh(Math.floor(Math.random() * 100));
            setAttachment([]);
            setValue('attachments', '');
            if (!res.ok) return;
            document.getElementById('createTicketModalClose').click();
            clearForm();
        });
    };

    useEffect(() => {
        setValue('employees', JSON.stringify([]));

        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            setProjects(res.projects);
            setEmployees(res.employees);
        });
    }, []);

    useEffect(() => {
        if (!props?.projectid) return;

        for (let [key, project] of Object.entries(projects)) {
            if (project?.id == props?.projectid) {
                setValue('project_id', props?.projectid);
                setSelectedProjectId({
                    id: project?.id,
                    vorname: project?.vorname,
                    name: project?.name,
                    telefonnummer: project?.telefonnummer,
                });
            }
        }

        //setValue('project_id', props?.projectid);
        //setSelectedProjectId({ id: props?.projectid });
        //handleProject(props?.projectid);
    }, [props?.projectid, projects]);

    useEffect(() => {
        if (!selectedProjectId?.id) return;

        callFetch("get-artikels-projects-employees?project_id=" + selectedProjectId?.id, "GET", []).then((res) => {
            //setProjects(res.projects);
            //setEmployees(res.employees);

            setPlanIds(res?.planIds);
        });

    }, [selectedProjectId?.id]);

    const clearForm = () => {
        reset();
        setAttachment([]);
        setZuweisenAnPerson(false);

        if (!props?.projectid) {
            setSelectedProjectId({ id: '' });
        } else {
            setValue('project_id', props?.projectid)
        }

        return;
        reset({
            id: 0,
            project_id: '',
            title: '',
            department: '',
            priority: '',
            start_date: '',
            end_date: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setEditForm(false);

    }

    const dropZoneInitialize = () => {

        return (
            <SoftDropzone

                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <a
                className="d-none"
                id="openDCAbnahmeTicket"
                onClick={() => {
                    setValue('department', 'Dachmontage');
                    setValue('title', 'Beschreibung');
                }}
                data-bs-toggle="modal"
                data-bs-target="#createTicketModalOpen"
            >openDCAbnahmeTicket</a>

            <a
                className="d-none"
                id="openACAbnahmeTicket"
                onClick={() => {
                    setValue('department', 'Elektromontage');
                    setValue('title', 'Beschreibung');
                }}
                data-bs-toggle="modal"
                data-bs-target="#createTicketModalOpen"
            >openACAbnahmeTicket</a>

            <a className="d-none" id="setTicketDefaultAssignorderprocessingId" onClick={(e) => {
                if( planIds[0]?.id){
                    setValue('assignorderprocessing_id', planIds[0]?.id);
                }
            }}>setPlanId</a>

            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div class="modal fade" id="createTicketModalOpen" tabindex="-1" role="dialog" aria-labelledby="createTicketModalOpenLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="createTicketModalClose"
                                    className="close btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center"
                                    style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"
                                        onClick={() => {
                                            clearForm();
                                        }}
                                    ></i></button>

                                <SoftBox p={2} lineHeight={1}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Create Ticket
                                    </SoftTypography>

                                    <div className="row">
                                        <div className="col-sm-3 mt-3">
                                            <div className="form-check form-switch">
                                                <input  {...register("type")} value="Allgemeine" className="form-check-input" onChange={handleCheckboxChange} checked={reclamation === 'Allgemeine' ? 'checked' : ''} type="checkbox" />
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">Allgemein</SoftTypography>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 mt-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" {...register("type")} value="Projektbezogene" onChange={handleCheckboxChangeProjekt} checked={reclamation === 'Projektbezogene' ? 'checked' : ''} type="checkbox" />
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">Projektbezogen</SoftTypography>
                                            </div>
                                        </div>

                                        {reclamation === 'Projektbezogene' ? (
                                            <div className="col-sm-6 mt-3">
                                                <div className="form-group ">
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={projects ? projects : {}}
                                                        getOptionLabel={(option) => ((option.id ? option.id : '') + (option.vorname ? ' - ' + option.vorname : '') + ' ' + (option?.name ? option?.name : ''))}
                                                        getOptionValue={(option) => option.id}
                                                        value={selectedProjectId}
                                                        onChange={(e) => {
                                                            setValue('project_id', e.id);
                                                            setSelectedProjectId({
                                                                id: e?.id,
                                                                vorname: e?.vorname,
                                                                name: e?.name,
                                                                telefonnummer: e?.telefonnummer,
                                                            });
                                                        }}
                                                        required
                                                    />
                                                    {selectedProjectId?.id ? (
                                                        <p className="mt-1" style={{ fontSize: '12px', textDecoration: 'underline', fontWeight: '500' }}>{((selectedProjectId?.id ? selectedProjectId?.id : '') + (selectedProjectId?.vorname ? ' - ' + selectedProjectId?.vorname : '') + ' ' + (selectedProjectId?.name ? selectedProjectId?.name : '') + ', ' + (selectedProjectId?.telefonnummer ? selectedProjectId?.telefonnummer : ''))}</p>
                                                    ) : ''}
                                                    <div className="invalid-feedback">{errors.project_id && errors.project_id.message}</div>
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="form-group">
                                            <label>Plan ID</label>
                                            <select
                                                className="form-control"
                                                {...register("assignorderprocessing_id")}
                                            >
                                                <option value={'0'}>{t('Select')}</option>
                                                {planIds ? planIds.map((planId, key) => (
                                                    <option value={planId?.id}>{'DC-'+planId?.id}</option>
                                                )) : ''}
                                            </select>
                                            <div className="invalid-feedback">{errors.assignorderprocessing_id && errors.assignorderprocessing_id.message}</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 mt-3">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    {...register("title", { required: true, })}
                                                />
                                                <div className="invalid-feedback">{errors.title && errors.title.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Abteilung</label>
                                                <select
                                                    className="form-control"
                                                    {...register("department")}
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacT") !== -1 ? (
                                                        <option value={'Dachmontage'}>Dachmontage</option>
                                                    ) : <></>}
                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyElekT") !== -1 ? (
                                                        <option value={'Elektromontage'}>Elektromontage</option>
                                                    ) : <></>}

                                                    {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacT") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("OnlyElekT") !== -1 ? (
                                                        <></>
                                                    ) : <>
                                                        <option value={'Dachmontage'}>Dachmontage</option>
                                                        <option value={'Elektromontage'}>Elektromontage</option>
                                                        <option value={'Einkauf'}>Einkauf</option>
                                                        <option value={'Buchhaltung'}>Buchhaltung</option>
                                                        <option value={'Vertrieb'}>Vertrieb</option>
                                                        <option value={'Fertigmeldung'}>Fertigmeldung</option>
                                                        <option value={'Personalmanagement'}>Personalmanagement</option>
                                                        <option value={'Beschwerde Backoffice'}>Beschwerde Backoffice</option>
                                                    </>}
                                                </select>
                                                <div className="invalid-feedback">{errors.department && errors.department.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Prioritat</label>
                                                <select
                                                    className="form-control"
                                                    {...register("priority", {
                                                        required: true,
                                                    })}
                                                    required>
                                                    <option value="">{t('Select')}</option>
                                                    <option value="Urgend">{t('Urgend')}</option>
                                                    <option value="Normal">{t('Normal')}</option>
                                                    <option value="Not Important">{t('Not Important')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.priority && errors.priority.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Start Date</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("start_date", { required: true, })}
                                                    defaultValue={startDate}
                                                />
                                                <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>End Date</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("end_date", { required: true, })}
                                                    defaultValue={endDate}
                                                />
                                                <div className="invalid-feedback">{errors.end_date && errors.end_date.message}</div>
                                            </div>
                                        </div>

                                        {reclamation === 'Allgemeine' ? (
                                            <div className="col-sm-12 mt-3">
                                                <div className="form-group">
                                                    <label>Partner-/ Lieferanten Nr.</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("partner", {
                                                            required: true,
                                                        })}
                                                        required>
                                                        <option value="">{t('Select')}</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.partner && errors.partner.message}</div>
                                                </div>
                                            </div>
                                        ) : ''}

                                        <div className="col-sm-12 mt-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    {...register("zuweisen_an_person", {
                                                        /*
                                                        onChange: (e) => {
                                                            setZuweisenAnPerson(e.target.checked);
                                                        }
                                                        */
                                                    })}
                                                    onClick={(e) => {
                                                        //console.log('zuweisen_an_person')
                                                        //console.log(e.target.checked)
                                                        setZuweisenAnPerson(e.target.checked);
                                                    }}
                                                    type="checkbox"
                                                    id="zuweisen_an_person"
                                                />
                                                <label class="m-0 mt-2">Zuweisen an Person</label>
                                            </div>
                                        </div>

                                        {zuweisenAnPerson ? (
                                            <div className="col-sm-12 mt-3">
                                                <div className="form-group">
                                                    <SoftSelect
                                                        options={employees}
                                                        getOptionLabel={(employees) => employees?.user?.name}
                                                        getOptionValue={(employees) => employees?.user?.id}
                                                        isMulti
                                                        onChange={(e) => {
                                                            var employees = [];
                                                            for (let [employee_key, employee] of Object.entries(e)) {
                                                                employees.push(employee?.user?.id)
                                                            }
                                                            setValue('employees', JSON.stringify(e));
                                                            // setValue('employees', employees.toString());
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : ''}


                                        <div className="col-sm-12 mt-3">
                                            <div className="form-group">
                                                <label>{t('Description')}</label>
                                                <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 mt-3">
                                            <div className="form-group">
                                                <label htmlFor="upload_ticket_files" className="text-primary" style={{ cursor: 'pointer' }}><i className="fa-solid fa-link"></i> {t('Upload Files')}</label>
                                                <input
                                                    type="file"
                                                    onChange={(e) => {
                                                        setLoading(true);
                                                        var formData = {};
                                                        for (let [key, val] of Object.entries(e.target.files)) {
                                                            formData['files[' + key + ']'] = e.target.files[key];
                                                        }

                                                        callFetch('attachment', "POST", formData, setError).then((res) => {
                                                            setLoading(false);

                                                            if (res.message == 'success') {
                                                                let data = attachments;
                                                                for (let [key, val] of Object.entries(res.data)) {
                                                                    data[data.length] = val;
                                                                }
                                                                setAttachment(data);
                                                                setValue('attachments', JSON.stringify(data));
                                                            }
                                                        });
                                                    }}
                                                    id="upload_ticket_files"
                                                    className="d-none"
                                                    multiple
                                                    accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <SoftBox p={0} className="order-processing">
                                        <Grid container spacing={3}>
                                            {attachments && attachments.map(function (data, i) {
                                                return (
                                                    <Grid item key={i} className="text-center">
                                                        <div>
                                                            <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment}>
                                                                {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                                                    <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment} />
                                                                ) : (
                                                                    <div className="placeholder">{data?.extension}</div>
                                                                )}

                                                            </a>
                                                        </div>
                                                        <a
                                                            onClick={() => {
                                                                removeAttachment(data?.id);
                                                            }}
                                                        >{t('Remove')}</a>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </SoftBox>

                                    <SoftBox className="d-none">
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography mb={2} component="label" variant="caption" fontWeight="bold">
                                                    Attachments
                                                </SoftTypography>

                                                <div className="row mb-2">
                                                    <div className="col-lg-12 mt-lg-0">
                                                        <div className="emb-4">
                                                            {editForm == false ? (
                                                                <>
                                                                    <SoftBox lineHeight={1}>
                                                                        <SoftBox>
                                                                            <SoftBox
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                justifyContent="flex-end"
                                                                                height="100%"
                                                                            >
                                                                                {/*dropZoneInitialize()*/}
                                                                            </SoftBox>
                                                                        </SoftBox>
                                                                    </SoftBox>
                                                                </>
                                                            ) : <></>}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </SoftBox>

                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                                        <SoftBox p={2} display="flex" justifyContent="flex-end">
                                            <button
                                                type="button"
                                                className="btn btn-danger mx-2"
                                                onClick={() => {
                                                    clearForm();
                                                    document.getElementById('createTicketModalClose').click();
                                                }}
                                            >
                                                {t('Cancel')}
                                            </button>

                                            {!saving && (
                                                <button type="submit" className="btn btn-primary">
                                                    {t('Create Ticket')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="button" className="btn btn-disabled" disabled>
                                                    {t('Creating ...')}
                                                </button>
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Create;
