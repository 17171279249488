import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSnackbar from "components/SoftSnackbar";

// Soft UI Dashboard PRO React example components
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import OrderLineChart from "./layouts/OrderLineChart";
import QuotationLineChart from "./layouts/QuotationLineChart";

import EvaluationLineChart from './layouts/EvaluationLineChart';
import ProjectStatusPieChart from './layouts/ProjectStatusPieChart';
import ProjectStatusCards from './layouts/ProjectStatusCards';
import TermineQouteTable from './layouts/TermineQouteTable';
import ProjectBezahltPieChart from './layouts/ProjectBezahltPieChart';
import ProjectTable from './layouts/ProjectTable';
import NewAufgabe from './layouts/NewAufgabe';
import TechnicianSalesStatusPieChart from './layouts/TechnicianSalesStatusPieChart';
import DashboardCalendar from './Calendar';
import TodaysCardList from "./employees-dashboard/component/TodaysCardList";

//import boards from "layouts/applications/kanban/data";
import Board from "@asseinfo/react-kanban";
import parse from "html-react-parser";
import { v4 as uuidv4 } from "uuid";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

function Index() {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({
    verkaufer: 0,
    created_at: 'Current Week',
  });

  const [menu, setMenu] = useState(null);



  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);


  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'All';
        setFilterData(data);
      }}>{t('All')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Today';
        setFilterData(data);
      }}>{t('Today')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Yeasterday';
        setFilterData(data);
      }}>{t('Yeasterday')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Current Week';
        setFilterData(data);
      }}>{t('Current Week')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Last 7 days';
        setFilterData(data);
      }}>{t('Last 7 days')}</MenuItem>
      <MenuItem onClick={() => {
        closeMenu();
        var data = filterData;
        data.created_at = 'Last 30 days';
        setFilterData(data);
      }}>{t('Last 30 days')}</MenuItem>
    </Menu>
  );

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Changes saved successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <div className="row">
            <div className="col-md-12">

              <SoftBox display="flex" gap={3} justifyContent="flex-end" ml={2}>
                {JSON.parse(Cookies.get('permissions')).indexOf("Export") !== -1 && false ? (
                  <SoftButton variant="outlined" color="secondary">
                    export&nbsp;&nbsp;
                    <Icon>folder</Icon>
                  </SoftButton>
                ) : <></>}

                {
                  Cookies.get("permissions").indexOf("ProBezPC") !== -1 ||
                    Cookies.get("permissions").indexOf("ProTab") !== -1 ||
                    Cookies.get("permissions").indexOf("StaCar") !== -1 ||
                    Cookies.get("permissions").indexOf("PSPCh") !== -1 ? (
                    <>
                      <SoftButton variant="gradient" color="dark" onClick={openMenu}>
                        {t(filterData?.created_at)}&nbsp;
                        <Icon>expand_more</Icon>
                      </SoftButton>
                      {renderMenu}
                    </>
                  ) : <></>}
              </SoftBox>


              <Grid container className="mt-3">
                <Grid item xs={12} lg={12} xl={12}>
                  <SoftBox>
                    <SoftBox>
                      <Grid container spacing={3}>

                        {Cookies.get("permissions").indexOf("StaCar") !== -1 ? (
                          <>
                            <Grid item xs={12} sm={12} lg={12}>
                              <ProjectStatusCards filterData={filterData} />
                            </Grid>
                          </>
                        ) : <></>}


                        {Cookies.get("permissions").indexOf("SPAnalyAllD") !== -1 || Cookies.get("permissions").indexOf("SPAnaly") !== -1 ? (
                          <Grid item xs={12} sm={12} lg={12}>



                            <Board
                              initialBoard={{
                                columns: [
                                  {
                                    id: uuidv4(),
                                    title: "In review",
                                    cards: [
                                      {
                                        id: uuidv4(),
                                        template: (
                                          <TechnicianSalesStatusPieChart
                                            title={'Today'}
                                            filterData={{
                                              created_at: 'Today'
                                            }}
                                          />
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    id: uuidv4(),
                                    title: "In review",
                                    cards: [
                                      {
                                        id: uuidv4(),
                                        template: (
                                          <TechnicianSalesStatusPieChart
                                            title={'Week'}
                                            filterData={{
                                              created_at: 'Current Week'
                                            }}
                                          />
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    id: uuidv4(),
                                    title: "In review",
                                    cards: [
                                      {
                                        id: uuidv4(),
                                        template: (
                                          <TechnicianSalesStatusPieChart
                                            title={'Month'}
                                            filterData={{
                                              created_at: 'Last 30 days'
                                            }}
                                          />
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    id: uuidv4(),
                                    title: "In review",
                                    cards: [
                                      {
                                        id: uuidv4(),
                                        template: (
                                          <TechnicianSalesStatusPieChart
                                            title={'Flexible'}
                                            filterData={{
                                              created_at: 'All'
                                            }}
                                          />
                                        ),
                                      },
                                    ],
                                  },
                                ],
                              }}
                              allowAddCard
                              allowAddColumn
                              renderColumnHeader={({ id, title }, { addCard }) => (
                                <>

                                </>
                              )}
                              renderCard={({ id, template }, { dragging }) => (
                                <>
                                  <SoftBox
                                    key={id}
                                    dragging={dragging.toString() || undefined}
                                    display="block"
                                    width="calc(450px - 40px)"
                                    bgColor="white"
                                    color="text"
                                    borderRadius="md"
                                    mt={2.5}
                                    py={1.875}
                                    px={1.875}
                                    lineHeight={1.5}
                                    sx={{
                                      fontSize: ({ typography: { size } }) => size.md,
                                    }}
                                  >
                                    {typeof template === "string" ? parse(template) : template}
                                  </SoftBox>
                                </>
                              )}
                              onCardNew={() => null}
                            />


                            {/*
                              <Grid container spacing={3} className="technicianSalesStatusPieChart-row">
                                <Grid item xs={12} sm={6} lg={3} className="technicianSalesStatusPieChart-col">
                                  <TechnicianSalesStatusPieChart
                                    title={'Today'}
                                    filterData={{
                                      created_at: 'Today'
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3} className="technicianSalesStatusPieChart-col">
                                  <TechnicianSalesStatusPieChart
                                    title={'Week'}
                                    filterData={{
                                      created_at: 'Current Week'
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3} className="technicianSalesStatusPieChart-col">
                                  <TechnicianSalesStatusPieChart
                                    title={'Month'}
                                    filterData={{
                                      created_at: 'Last 30 days'
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3} className="technicianSalesStatusPieChart-col">
                                  <TechnicianSalesStatusPieChart
                                    title={'Flexible'}
                                    filterData={{
                                      created_at: 'All'
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            */}

                          </Grid>
                        ) : <></>}



                        {Cookies.get("permissions").indexOf("StaCar") !== -1 ? (
                          <>
                            <Grid item xs={12} sm={12} lg={7}>
                              <EvaluationLineChart filterData={filterData} />
                            </Grid>
                          </>
                        ) : <></>}

                        {Cookies.get("permissions").indexOf("PSPCh") !== -1 ? (
                          <>
                            <Grid item xs={12} sm={12} lg={5}>
                              <ProjectStatusPieChart filterData={filterData} />
                            </Grid>
                          </>
                        ) : <></>}

                        {Cookies.get("permissions").indexOf("TerQTa") !== -1 && (
                          <Grid item xs={12} sm={12} lg={7}>
                            <TermineQouteTable />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12} lg={5}>
                          {Cookies.get("permissions").indexOf("ProBezPC") !== -1 && (
                            <SoftBox>
                              <ProjectBezahltPieChart filterData={filterData} />
                            </SoftBox>
                          )}

                          {Cookies.get("permissions").indexOf("NeuT") !== -1 && (
                            <SoftBox mt={3} height="10rem">
                              <NavLink to="/projects/create">
                                <PlaceholderCard
                                  title={{ variant: "h6", text: "NeuT" }}
                                  hasBorder
                                />
                              </NavLink>
                            </SoftBox>
                          )}
                        </Grid>

                        {Cookies.get("permissions").indexOf("ProTab") !== -1 && (
                          <Grid item xs={12} sm={12} lg={12}>
                            <ProjectTable filterData={filterData} />
                          </Grid>
                        )}


                      </Grid>
                    </SoftBox>
                  </SoftBox>

                  <Grid container spacing={3} py={3}>
                    <OrderLineChart />

                    <QuotationLineChart />

                    {(Cookies.get("user") && JSON.parse(Cookies.get("user")).rolename == "Call Center") ||
                      JSON.parse(Cookies.get("user")).rolename == "DC Technician" ? (
                      <></>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ height: "185px", cursor: "pointer" }}
                          data-bs-toggle="modal"
                          data-bs-target="#newTabModal"
                        >
                          <PlaceholderCard
                            title={{ variant: "h6", text: t("New Tab") }}
                            hasBorder
                            height="162px"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <DashboardCalendar
                      teamsEvent={false}
                      readyToLoad={true}
                      projects={true}
                      assign_orders={true}
                      reclamations={true}
                      absence={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {renderSuccessSB}
          </div>
        </SoftBox>
      </SoftBox>

      <NewAufgabe />
      {JSON.parse(Cookies.get('permissions')).indexOf("TodayCL") !== -1 ? (
        <TodaysCardList />
      ) : <></>}
    </>
  );
}

export default Index;
