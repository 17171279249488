import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import SoftAlert from "components/SoftAlert";
import SoftSnackbar from "components/SoftSnackbar";
import { Checkbox } from "@mui/material";

const LieferantenCreate = (props) => {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState('EUR');
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [receivedError, setReceivedError] = useState(null);
    const [einkaufData, setEinkaufData] = useState([])
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("lieferanten/create", "GET", []).then((res) => {
            setValue('lieferanten_nummer', res?.lieferanten_nummer);
            setRefresh(refresh + 1)
        });
    }, [refresh2]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Successfully created"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("lieferanten", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
            props?.setRefresh((Math.random() * 1000) + 1000);
            setRefresh2(refresh2 + 1);
            document.getElementById('createLieferantentModalClose').click();
            reset();
            openSuccessSB();
        });
    };

    return (
        <>
            <button
                type="button"
                id="createLieferantentModalBtn"
                className="btn bg-gradient-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#createLieferantentModal"
            >{t('create Lieferantent')}</button>

            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="modal fade" id="createLieferantentModal" tabindex="-1" role="dialog" aria-labelledby="createLieferantentModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="createLieferantentModalLabel" style={{ fontWeight: 'bold' }}>{t('Lieferanten & Partner Hinzufugen')}</h6>
                                <button
                                    type="button"
                                    id="createLieferantentModalClose"
                                    className="btn-close text-dark"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body tb-ac-cs">

                                <div className="row g-3">
                                    <div className="col-md-6 mb-4">
                                        <label>{t('Lieferantennummer.')} *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('L-100001')}
                                            {...register("lieferanten_nummer", {
                                                required: true,
                                            })}
                                            readOnly
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.lieferanten_nummer && errors.lieferanten_nummer.message}</div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label>
                                                {t('Firma Name')} *
                                            </label>
                                            <br />
                                            <input type="text" className="form-control" placeholder="z.B. Jhon Doe" {...register('firma_name', { required: true })} required />
                                            <div className="invalid-feedback">{errors.firma_name && errors.firma_name.message}</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label>
                                                {t('E-Mail')}
                                            </label>
                                            <br />
                                            <input type="text" placeholder="email@mail.com" className="form-control" {...register('email', { required: false })} />
                                            <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 ">
                                        <div className="form-group mb-4">
                                            <label>
                                                {t('Logo')}
                                            </label>
                                            <input type="file" className="form-control" placeholder="logo" {...register('logo', { required: false })} />
                                            <div className="invalid-feedback">{errors.date && errors.date.message}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label>
                                            {t('Telephone')}
                                        </label>
                                        <input type="number" className="form-control" placeholder="telephone" {...register('telephone', { required: false })} />
                                        <div className="invalid-feedback">{errors.date && errors.date.message}</div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <label>{t('Address')}</label>
                                        <textarea className="form-control" rows="3"
                                            defaultValue=""
                                            placeholder="eg. Address"
                                            {...register("description")}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-block">
                                {receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}

                                {!saving && (
                                    <button type="submit" className="btn btn-primary mb-0 float-end">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled mb-0 float-end" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {renderSuccessSB}
        </>
    );
}

export default LieferantenCreate