import { React, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu, MenuItem, setRef } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from 'pages/project/Timeline';
import ImportModal from '../importer/ImportModal';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import ExtraInfo from 'pages/project/ExtraInfo';
import {
    useSoftUIController,
    setOpenConfigurator,
    setTransparentSidenav,
    setMiniSidenav,
    setFixedNavbar,
    setSidenavColor,
} from "context";
import Swal from "sweetalert2";

function ProjectIndexTable() {
    const fp = useRef(null);
    const fpt = useRef(null);
    const [searchData, setSearchData] = useState([])
    const [projectNumbers, setProjectNumbers] = useState([])
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const [kundenNames, setKundenNames] = useState([])
    const [orts, setOrts] = useState([])
    const [menuNumber, setMenuNumber] = useState(false);
    const [menu, setMenu] = useState(false);
    const [menuOrt, setMenuOrt] = useState(false);
    const [invoice, setInvoice] = useState('');
    const [callCenter, setCallCenter] = useState('');
    const [reklamation, setReklamation] = useState('');
    const [dachmontage, setDachmontage] = useState('');
    const [elektromontage, setElektromontage] = useState('');
    const [more, setMore] = useState(false);
    const [ac, setAC] = useState('Erfolgreich');
    const [dc, setDC] = useState('');
    const [call_centers, setCallCenters] = useState({});
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor } = controller;
    const [disabled, setDisabled] = useState(false);
    const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
    // Use the useEffect hook to change the button state for the sidenav type based on window size.
    useEffect(() => {

        callFetch("get-callcenters-filter", "GET", []).then((res) => {
            setCallCenters(res.call_centers);
        });

        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }

        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener("resize", handleDisabled);

        // Call the handleDisabled function to set the state with the initial value.
        setOpenConfigurator(dispatch, false);
        handleDisabled();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleDisabled);

    }, []);

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);


    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDateTerm, setStartDateTerm] = useState('');
    const [endDateTerm, setEndDateTerm] = useState('');
    const { t } = useTranslation();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [vorname_name, setVornameName] = useState("");
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [vkb_name, setVKBName] = useState("");
    const [ort, setOrt] = useState("");
    const [project_nr, setProjectNr] = useState("");
    const [filterKeys, setFilterKeys] = useState({});
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const [proID, setProID] = useState(0);
    const [action, setAction] = useState('');
    const [fertigmeldungErfolgreich, setFertigmeldungErfolgreich] = useState(0);
    const handleDateChange = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDate('undefined');
            setEndDate('undefined');
        } else {
            const [startDate, endDate] = selectedDates;
            setStartDate(dateFormat(startDate, "yyyy-mm-dd"));
            setEndDate(dateFormat(endDate, "yyyy-mm-dd"));
        }
    };

    const handleChangeAction = (action) => {
        setAction(action);
    }

    const showAlert = () => {
        const newSwal = Swal.mixin({
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        });

        newSwal
            .fire({
                title: t('Are you sure?'),
                text: t("You won't be able to revert this!"),
                // icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Yes, do it!'),
                cancelButtonText: t('Cancel')
            })
            .then((result) => {
                if (result.isConfirmed) {
                    var ids = [];
                    selectedRows.map((row) => {
                        ids.push(row.id);
                    })

                    var formData = new FormData;
                    formData.ids = ids;

                    callFetch("delete-cancel-projects", "POST", formData).then((res) => {
                        setRefresh(refresh + 1);
                    });

                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                }
            });
    };

    const handleSelectedRowsApply = () => {
        showAlert();
    }

    const handleDateChangeTerm = (selectedDates) => {
        // Handle selected date range 
        if (Object.keys(selectedDates).length <= 0) {
            setStartDateTerm('undefined');
            setEndDateTerm('undefined');
        } else {
            const [startDateTermX, endDateTermX] = selectedDates;
            setStartDateTerm(dateFormat(startDateTermX, "yyyy-mm-dd"));
            setEndDateTerm(dateFormat(endDateTermX, "yyyy-mm-dd"));
        }
    };
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const cancelProject = (cancel, id) => {

        callFetch("project/cencel", "POST", {
            cancel: cancel,
            id: id,
        }, []).then((res) => {


            setRefresh(refresh + 1)
        });


        setRefresh(refresh + 1)
    }

    const handleChangeMin = (value) => {
        setMin(value);
    };

    const handleChangeMax = (value) => {
        setMax(value);
    };

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>
                <Timeline datas={data} title="Project Status" />
            </Grid>
        </Menu>
    );


    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        console.log(selectedRows);
        setSelectedRows(selectedRows);
    };

    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const handleClearButton = () => {
        document.getElementById('ortInput').value = '';
        document.getElementById('min').value = '';
        document.getElementById('max').value = '';
        document.getElementById('projectidInput').value = '';
        document.getElementById('kundenInput').value = '';
        document.getElementById('vkbInput').value = '';
        fp.current.flatpickr.clear();
        fpt.current.flatpickr.clear();
        setInvoice('');
        setReklamation('');
        setDachmontage('');
        setElektromontage('');
        setAC('');
        setDC('');
        setMore(false);
    }

    const handleAddMoreButton = () => {
        setMore(true);
    }




    const tableHeadings = [
        {
            name: t('Project'),
            width: '90px',
            cell: row => <NavLink to={'/projects/' + row.id} className="text-primary">{row.id}</NavLink>,
            selector: row => row.id,
            sortable: true
        },
        {
            name: t('Customer'),
            width: '200px',
            cell: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
            sortable: true
        },
        {
            name: t('VKB Name'),
            cell: row => <NavLink to={'/projects/' + row.id}>{row?.salse_person?.name}</NavLink>,
            selector: row => row?.salse_person?.name,
            sortable: true
        },
        {
            name: t('Erstellt am'),
            width: '120px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
            sortable: true
        },
        {
            name: t('Termindatum'),
            width: '130px',
            selector: row => dateFormat(row?.ereignisdatum, "dd.mm.yyyy"),
            sortable: true
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <span className="badge badge-dot me-4">
                        {row.stornieren == 1 ? (
                            <>
                                <i className="bg-danger"></i>
                                <span className="text-dark text-xs">{'Storno'}</span>
                            </>
                        ) : (
                            <>
                                {row?.status && row.status != 'null' ? (
                                    <>
                                        {row.status == '1-Verkauft' ? <i className="bg-success"></i>
                                            : row.status == '2-Kontte nicht verkauft werden' ? <i className="bg-primary"></i>
                                                : row.status == '3-Angebot' ? <i className="bg-secondary"></i>
                                                    : row.status == '4-Überleger' ? <i className="bg-info"></i>
                                                        : row.status == '5-Kunde war nicht da' ? <i className="bg-warning"></i>
                                                            : row.status == '6-Kein Interesse / Keine Beratung' ? <i className="bg-dark"></i>
                                                                : row.status == '7-Technisch nicht möglich' ? <i className="bg-dark"></i>
                                                                    : row.status == '0-Zeitlich nicht geschaft' ? <i className="bg-dark"></i>
                                                                        : <i className="bg-dark"></i>
                                        }
                                        <span className="text-dark text-xs">{t(row.status)}</span>
                                    </>
                                ) : row?.termine_qoute?.status && row?.termine_qoute?.status != 'null' ? (
                                    <>
                                        {row?.termine_qoute?.status == '1-Verkauft' ? <i className="bg-success"></i>
                                            : row?.termine_qoute?.status == '2-Kontte nicht verkauft werden' ? <i className="bg-primary"></i>
                                                : row?.termine_qoute?.status == '3-Angebot' ? <i className="bg-secondary"></i>
                                                    : row?.termine_qoute?.status == '4-Überleger' ? <i className="bg-info"></i>
                                                        : row?.termine_qoute?.status == '5-Kunde war nicht da' ? <i className="bg-warning"></i>
                                                            : row?.termine_qoute?.status == '6-Kein Interesse / Keine Beratung' ? <i className="bg-dark"></i>
                                                                : row?.termine_qoute?.status == '7-Technisch nicht möglich' ? <i className="bg-dark"></i>
                                                                    : row?.termine_qoute?.status == '0-Zeitlich nicht geschaft' ? <i className="bg-dark"></i>
                                                                        : <i className="bg-dark"></i>
                                        }
                                        <span className="text-dark text-xs">{t(row?.termine_qoute?.status)}</span>
                                    </>
                                ) : <span className="text-dark text-xs">{'--'}</span>}
                            </>
                        )
                        }

                    </span>
                </>
            ),
        },
        {
            name: t('Price'),
            cell: row => ((
                <NumericFormat
                    value={Number(row?.quations?.gesami_netto)}
                    displayType="text"
                    thousandSeparator={"."}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={' € '}
                />
            )),
            selector: row => row?.quations?.gesami_netto,
            sortable: true
        },
        {
            name: t('Ort'),
            selector: row => row?.ort,
            sortable: true
        },
        {
            name: t('Project Status'),
            width: '180px',
            cell: row => <>
                {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename != 'Call Center' ? (
                    <div onClick={handleOpenMenu} className="d-flex align-items-center cursor-pointer" style={{ color: "#0048B1", textDecoration: "underline" }}>
                        <span onClick={() => projectData(row.id)} className="d-flex align-items-center cursor-pointer">

                            <Icon
                                color={row?.corrections_revision?.length > 0 ? 'error' : ''}
                                className="mx-1"
                            >info</Icon>
                            {t(
                                row?.cancel ? t('Project Cancelled')
                                    : row?.assignorderprocessings?.length >= 2 ? 'Elektromontage'
                                        : row?.assignorderprocessings?.length == 1 ? 'Dachmontage'
                                            : row?.projectstatus
                            )}
                        </span>
                    </div>
                ) : ''}
            </>,

        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrU") !== -1 ? (
                                    <>
                                        <li><a
                                            href='#'
                                            className="dropdown-item"
                                            onClick={() => {
                                                cancelProject(row.cancel ? 0 : 1, row.id);
                                            }}
                                        >{t(row.cancel ? 'Storno aufheben' : 'Stornieren')}</a>
                                        </li>
                                        <li><NavLink to={'/projects/' + row.id} className="dropdown-item">{t('Edit')}</NavLink></li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {JSON.parse(Cookies.get('permissions')).indexOf("PrD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'projects', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {

        document.getElementsByClassName('flatpickr-input')[0].setAttribute('placeholder', 'Select Dates');
        document.getElementsByClassName('flatpickr-input')[1].setAttribute('placeholder', 'Select Dates');
        setLoading(true);

        if (Cookies.get('cacehQuery') && Cookies.get('cacehQuery') == 'yes' && JSON.parse(Cookies.get('variables'))?.fertigmeldung) {
            var parameters = JSON.parse(Cookies.get('variables')).fertigmeldung;
        } else {
            var parameters = "search=" + searchKey + "&page=" + pageNumber + "&vorname_name=" + vorname_name + "&project_nr=" + project_nr + "&created_start=" + startDate + "&created_end=" + endDate + "&appointment_start=" + startDateTerm + "&appointment_end=" + endDateTerm + "&ort=" + ort + '&min=' + min + '&max=' + max + '&vkb_name=' + vkb_name + '&invoice=' + invoice + '&reklamation=' + reklamation + '&dachmontage=' + dachmontage + '&elektromontage=' + elektromontage + '&ac=' + ac + '&dc=' + dc + '&call_center=' + callCenter + '&fertigmeldungErfolgreich=' + fertigmeldungErfolgreich;;
            for (let [key, value] of Object.entries(filterKeys)) {
                parameters += "&" + key + "=" + value;
            }
        }

        var oldVariables = (Cookies.get('variables') ? JSON.parse(Cookies.get('variables')) : {});
        oldVariables.fertigmeldung = parameters;
        Cookies.set('variables', JSON.stringify(oldVariables));


        callFetch("projects?" + parameters, "GET", []).then((res) => {
            setTableData(res.data.projects);

            if (res.project_numbers != 'null') {
                if (Object.keys(res.project_numbers).length > 1) {
                    setProjectNumbers(res.project_numbers);
                    setMenuNumber(true);
                } else {
                    setProjectNumbers([]);
                    setMenuNumber(false);
                }
            }

            if (res.vor_names != 'null') {
                if (Object.keys(res.vor_names).length > 1) {
                    setKundenNames(res.vor_names);
                    setMenu(true);
                } else {
                    setKundenNames([]);
                    setMenu(false);
                }
            }

            if (res.orts != 'null') {
                if (Object.keys(res.orts).length > 1) {
                    setOrts(res.orts);
                    setMenuOrt(true);
                } else {
                    setOrts([]);
                    setMenuOrt(false);
                }
            }

            setLoading(false);
            Cookies.set('cacehQuery', '')

        });
    }, [searchKey, fertigmeldungErfolgreich, vorname_name, filterKeys, pageNumber, refresh, project_nr, startDate, endDate, startDateTerm, endDateTerm, ort, min, max, vkb_name, invoice, reklamation, dachmontage, elektromontage, ac, dc, callCenter]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <DataTable
            columns={tableHeadings}
            data={tabledata?.data}
            noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
            className='data-table'
            pagination
            highlightOnHover
            selectableRows
            expandableRowsComponent={({ data }) => <ExtraInfo data={data} />}
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggledClearRows}
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={tabledata?.total}
            onChangePage={handlePageChange}
            expandableRows
            paginationComponent={BootyPagination}
            subHeader
            subHeaderComponent={
                <>
                    {selectedRows?.length >= 1 &&

                        <div style={{ position: 'absolute', left: '15px', width: '250px', marginTop: '15px' }}>
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <select className='form-control' onChange={(e) => handleChangeAction(e.target.value)}>
                                        <option value="delete">Löschen</option>
                                    </select>
                                </div>
                                <div className='col-sm-4'>
                                    <buttn className="btn btn-secondary" style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={() => handleSelectedRowsApply()}>Anwenden</buttn>
                                </div>
                            </div>
                        </div>

                    }

                    <button onClick={handleConfiguratorOpen} className='btn btn-outline-dark mx-2 d-none' style={{ paddingTop: '10px', paddingBottom: '10px', margin: '0px' }}><i class="fa-solid fa-filter"></i> Filter</button>
                   
                    <input
                        type="text"
                        placeholder={t("Search...")}
                        className=' form-control w-sm-50 w-md-25 w-lg-15 ms-1 mt-1'
                        defaultValue={searchKey}
                        /*
                        onChange={(e)=> {
                            setSearchKey(e.target.value);
                        }}
                        */
                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setSearchKey(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                </>
            }
        />


        <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
            <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}
            >
                <SoftBox>
                    <SoftTypography variant="h5">Filters</SoftTypography>
                </SoftBox>

                <Icon
                    sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                        fontSize: `${size.md} !important`,
                        fontWeight: `${fontWeightBold} !important`,
                        stroke: dark.main,
                        strokeWidth: "2px",
                        cursor: "pointer",
                        mt: 2,
                    })}
                    onClick={handleCloseConfigurator}
                >
                    close
                </Icon>
            </SoftBox>

            <Divider />

            <SoftBox pt={1.25} pb={3} px={3}>
                <label>Project Nr</label>
                <div className='position-relative'>
                    <input
                        id="projectidInput"
                        type="text"
                        placeholder={t("Projekt Nr") + '...'}
                        className='form-control mb-2'
                        defaultValue={project_nr}

                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setProjectNr(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                    {
                        projectNumbers?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                            {
                                menuNumber && <div className="card-body p-0 m-0" >
                                    <ul className="p-1 m-0">
                                        {
                                            projectNumbers.map((items, index) => (
                                                <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                    {
                                                        items?.id ? (
                                                            <NavLink
                                                                style={{ color: "#344767" }}
                                                                to={''}
                                                                onClick={() => {
                                                                    setProjectNumbers([]);
                                                                    document.getElementById('projectidInput').value = items.id;
                                                                    setProjectNr(items.id);
                                                                }}
                                                            >
                                                                {items?.id} <br />
                                                            </NavLink>
                                                        ) : ''
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>

                <label>{t("Kundenname")}</label>
                <div className='position-relative'>
                    <input
                        id='kundenInput'
                        type="text"
                        placeholder={t("Kundenname") + '...'}
                        className='form-control mb-2'
                        defaultValue={vorname_name}
                        /*
                        onChange={(e)=> {
                            setSearchKey(e.target.value);
                        }}
                        */
                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setVornameName(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />
                    {
                        kundenNames?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                            {
                                menu && <div className="card-body p-0 m-0" >
                                    <ul className="p-1 m-0">
                                        {
                                            kundenNames.map((items, index) => (
                                                <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                    {
                                                        items?.id ? (
                                                            <NavLink
                                                                style={{ color: "#344767" }}
                                                                to={''}
                                                                onClick={() => {
                                                                    setKundenNames([]);
                                                                    setVornameName(items.vorname + ' ' + items.name);
                                                                    document.getElementById('kundenInput').value = items.vorname + ' ' + items.name;
                                                                }}
                                                            >
                                                                {items.vorname + ' ' + items.name} <br />
                                                            </NavLink>
                                                        ) : ''
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>
                <label>{t("VKB Name")}</label>
                <input
                    id='vkbInput'
                    type="text"
                    placeholder={t("VKB Name") + '...'}
                    className='form-control mb-2'
                    defaultValue={vkb_name}
                    /*
                    onChange={(e)=> {
                        setSearchKey(e.target.value);
                    }}
                    */
                    onKeyUp={(e) => {
                        clearTimeout(typingTimer);
                        typingTimer = setTimeout(() => {
                            setTableData([]);
                            setVKBName(e.target.value);
                        }, 1500);
                    }}
                    onKeyDown={(e) => {
                        clearTimeout(typingTimer);
                    }}
                />

                <label>{t("Erstellt am")}</label>
                <Flatpickr
                    ref={fp}
                    options={{
                        mode: 'range',
                        dateFormat: 'Y-m-d',
                    }}
                    onChange={handleDateChange}
                />
                <label>{t("Termindatum")}</label>
                <Flatpickr
                    ref={fpt}
                    options={{
                        mode: 'range',
                        dateFormat: 'Y-m-d',
                    }}
                    onChange={handleDateChangeTerm}
                />



                <label>{t("Status")}</label>
                <Select
                    placeholder={t('Select')}
                    options={[
                        { value: 'Null', label: '--' },
                        { value: '1-Verkauft', label: '1-Verkauft' },
                        { value: '2-Kontte nicht verkauft werden', label: '2-Kontte nicht verkauft werden' },
                        { value: '3-Angebot', label: '3-Angebot' },
                        { value: '4-Überleger', label: '4-Überleger' },
                        { value: '5-Kunde war nicht da', label: '5-Kunde war nicht da' },
                        { value: '6-Kein Interesse / Keine Beratung', label: '6-Kein Interesse / Keine Beratung' },
                        { value: '7-Technisch nicht möglich', label: '7-Technisch nicht möglich' },
                        { value: '8-Storno', label: '8-Storno' },
                        { value: '9-Kundenseitig abgesagt', label: '9-Kundenseitig abgesagt' },
                        { value: '10-Entscheider waren nicht da', label: '10-Entscheider waren nicht da' },
                        { value: '0-Zeitlich nicht geschaft', label: '0-Zeitlich nicht geschaft' },
                    ]}
                    onChange={(e) => {
                        var dataKeys = filterKeys;
                        var status = [];
                        for (let [option_key, option] of Object.entries(e)) {
                            status.push(option?.value)
                        }

                        //dataKeys.status = status.toString();
                        dataKeys.status = JSON.stringify(status);
                        setFilterKeys(dataKeys);
                        setRefresh(refresh + 1);
                    }}
                    className='react-select-filter mb-2'
                    isMulti
                />


                <label>{t("Projekt Status")}</label>
                <Select
                    placeholder={t('Select')}
                    options={[
                        { value: '', label: '--' },
                        { value: 'Elektromontage', label: 'Elektromontage' },
                        { value: 'Dachmontage', label: 'Dachmontage' },
                        { value: 'Order', label: t('Order') },
                        { value: 'Angebot', label: t('Angebot') },
                        { value: 'Appointment', label: t('Appointment') },
                    ]}
                    onChange={(e) => {
                        var dataKeys = filterKeys;
                        var status = [];

                        console.log(e);

                        status.push(e?.value);
                        /*
                        for (let [option_key, option] of Object.entries(e)) {
                            status.push(option?.value)
                        }
                        */
                        dataKeys.project_status = JSON.stringify(status);
                        setFilterKeys(dataKeys);
                        setRefresh(refresh + 1);
                    }}
                    className='react-select-filter mb-2'
                //isMulti
                />

                <div>

                    <label>{t("Price")}</label>
                    <div class="input-group-c">
                        <label for="min">Min:</label>
                        <input type="number" id="min" name="min" onChange={(e) => handleChangeMin(e.target.value)} placeholder="Minimum value" />
                        <label for="max">Max:</label>
                        <input type="number" id="max" name="max" onChange={(e) => handleChangeMax(e.target.value)} placeholder="Maximum value" />
                    </div>
                </div>
                <label>{t("Ort")}</label>
                <div className='position-relative'>
                    <input
                        id='ortInput'
                        type="text"
                        placeholder={t("Ort...")}
                        className=' form-control mb-2'
                        defaultValue={ort}
                        /*
                        onChange={(e)=> {
                            setSearchKey(e.target.value);
                        }}
                        */
                        onKeyUp={(e) => {
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(() => {
                                setTableData([]);
                                setOrt(e.target.value);
                            }, 1500);
                        }}
                        onKeyDown={(e) => {
                            clearTimeout(typingTimer);
                        }}
                    />

                    {
                        orts?.length >= 0 && <div className="card position-absolute p-0 m-0 me-10 top-100 w-100 rounded-0 mt-1" style={{ zIndex: '999' }}>
                            {
                                menuOrt && <div className="card-body p-0 m-0" >
                                    <ul className="p-1 m-0">
                                        {
                                            orts.map((items, index) => (
                                                <MenuItem style={{ text: "black", hover: { color: 'none' } }} key={index}>
                                                    {
                                                        items?.id ? (
                                                            <NavLink
                                                                style={{ color: "#344767" }}
                                                                to={''}
                                                                onClick={() => {
                                                                    setOrts([]);
                                                                    setOrt(items.ort);
                                                                    document.getElementById('ortInput').value = items.ort;
                                                                }}
                                                            >
                                                                {items.ort} <br />
                                                            </NavLink>
                                                        ) : ''
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }

                </div>

                {/* //more */}
                {more ? (
                    <div>
                        <label>{t("Call Center")}</label>
                        <select className='form-control mb-2' onChange={(e) => setCallCenter(e.target.value)}>
                            <option>Select</option>
                            {call_centers?.length > 0 && (
                                call_centers.map((center) => (
                                    <option value={center.id}>{center.name}</option>
                                ))
                            )}
                        </select>

                        <label>{t("Invoice")}</label>
                        <select className='form-control mb-2' onChange={(e) => setInvoice(e.target.value)}>
                            <option>Select</option>
                            <option value="yes">{t('Yes')}</option>
                            <option value="no">{t('No')}</option>
                        </select>

                        <label>{t("Reklamation")}</label>
                        <select className='form-control mb-2' onChange={(e) => setReklamation(e.target.value)}>
                            <option>Select</option>
                            <option value="yes">{t('Yes')}</option>
                            <option value="no">{t('No')}</option>
                        </select>

                        <label>{t("Dachmontage")}</label>
                        <select className='form-control mb-2' onChange={(e) => setDachmontage(e.target.value)}>
                            <option>Select</option>
                            <option value="yes">{t('Yes')}</option>
                            <option value="no">{t('No')}</option>
                        </select>

                        <label>{t("Elektromontage")}</label>
                        <select className='form-control mb-2' onChange={(e) => setElektromontage(e.target.value)}>
                            <option>Select</option>
                            <option value="yes">{t('Yes')}</option>
                            <option value="no">{t('No')}</option>
                        </select>

                        {/*
                        <label>{t("AC Abnahme")}</label>
                        <select className='form-control mb-2' onChange={(e) => setAC(e.target.value)}>
                            <option>Select</option>
                            <option value="Erfolgreich">{t('Erfolgreich')}</option>
                            <option value="Nicht Erfolgreich">{t('Nicht Erfolgreich')}</option>
                        </select>
                        */}

                        <label>{t("DC Abnahme")}</label>
                        <select className='form-control mb-2' onChange={(e) => setDC(e.target.value)}>
                            <option>Select</option>
                            <option value="Erfolgreich">{t('Erfolgreich')}</option>
                            <option value="Nicht Erfolgreich">{t('Nicht Erfolgreich')}</option>
                        </select>
                    </div>
                ) : <button type='button' className='btn btn-link p-0 mt-2' style={{ color: '#005498' }} onClick={() => handleAddMoreButton()}>More</button>}

                <br />
                <button type='button' className='btn btn-outline-dark mt-2' onClick={() => handleClearButton()}>Clear</button>
            </SoftBox>
        </ConfiguratorRoot>


        <ImportModal type={'project'} refreshParent={() => setRefresh(refresh + 1)} />
        <ImportModal type={'projectWithSales'} refreshParent={() => setRefresh(refresh + 1)} />
    </>
}

export default ProjectIndexTable;
