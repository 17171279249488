import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";

// Images
import callFetch from "../../../../helpers/callFetch";
import ProjectStatus from 'pages/project/ProjectStatus';
import Cookies from 'js-cookie';

function TodaysCardList() {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [todays, setTodays] = useState([]);
  const [today, setToday] = useState(true);
  const [unbewertet, setUnbewertet] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

 
  useEffect(() => {
    setLoading(true);
    callFetch("projects/todays", "GET", []).then((res) => {
      setData(res?.data);

      if (res?.data?.unbewertet_total) {
        //document.body.insertAdjacentHTML('beforeend', '<div class="deshboard-backdrop show"></div>');
        if(JSON.parse(Cookies.get('permissions')).indexOf("UnveCC") !== -1){
          Cookies.set('UnveCC', 'yes');
          //document.body.insertAdjacentHTML('beforeend', '<div class="deshboard-backdrop show"></div>');
        }else{
          Cookies.set('UnveCC', 'no');
        }

        setUnbewertet('yes');
      }else{
        Cookies.set('UnveCC', 'no');
        setUnbewertet('');
      }

      setLoading(false);
    });
  }, [isUpdate, refresh]);

  useEffect(() => {
    if (unbewertet == 'yes' && data?.unbewertet) {
      setTodays(data?.unbewertet);
    } else if (data?.today) {
      setTodays(data?.today);
    }
  }, [data?.today_total, data?.unbewertet_total, unbewertet]);


  return (
    <>
      {today ? <div className="div todays-card">
        <div className=" " style={{ background: '#fff', borderRadius: '5px', padding: '0px', paddingLeft: '15px', paddingBottom: '15px' }}>
          <div className="card" style={{ boxShadow: 'none', borderRight: '1px solid #cacaca', borderRadius: '0px', paddingRight: '8px' }}>
            <button style={{ color: '#867e7e' }} onClick={() => setToday(false)} className="right-arrow-btn">
              <i className="fas fa-angle-left"></i>
            </button>
            <div className="card-body callcenter-area" style={{ background: '#fff', height: 'calc(100vh - 38px)', overflowY: 'auto', padding: '0px', paddingRight: '15px', paddingTop: '15px' }}>

              {loading ? <h6 style={{ textAlign: 'center', marginTop: '100%' }}>Loading...</h6> : <div>
                <button
                  style={{
                    fontSize: '10px',
                    fontWeight: '700',
                    marginRight: '15px',
                    background: (!unbewertet ? '#0048B1' : 'rgb(0 72 177 / 20%)'),
                    padding: '0px 8px 0px 8px',
                    width: '135px',
                    height: '35px',
                    borderRadius: '8px',
                    color: (!unbewertet ? '#fff' : '#2D3748'),
                    border: 'none'
                  }}
                  onClick={() => {
                    if(!data?.unbewertet_total){
                      setUnbewertet('');
                    }
                  }}
                >Heute ({data?.today_total})</button>

                <button
                  style={{
                    fontSize: '10px',
                    fontWeight: '700',
                    background: (unbewertet ? '#E53E3E' : '#E53E3E2B'),
                    padding: '0px 8px 0px 8px',
                    width: '135px',
                    height: '35px',
                    borderRadius: '12px',
                    color: (unbewertet ? '#fff' : '#2D3748'),
                    border: 'none'
                  }}
                  onClick={() => {
                    setUnbewertet('yes');
                  }}
                >Unbewertet ({data?.unbewertet_total})</button>

                {todays.length < 1 ? <h6 style={{ textAlign: 'center', marginTop: '100%' }}>Nothing for today...</h6> : ''}

                {todays && todays.map((item, index) => (
                  <div className="card mt-3" style={{ border: '1px solid #CACACA', borderLeft: '8px solid #48BB78', boxShadow: 'none' }}>
                    <div className="card-body" style={{ padding: '8px 15px' }}>
                      <div className="row mb-3">
                        <div className="col-sm-7">
                          <SoftTypography variant="caption" style={{ fontWeight: '300', color: '#A0A9C0' }}>{dateFormat(item.date, "dd.mm.yyyy")}</SoftTypography>
                          <SoftTypography variant="caption" style={{ color: '#A0A9C0' }} fontWeight="bold">{item.time}</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                          <SoftBox display="flex" className="align-items-end" style={{ float: 'right' }}>
                            <div style={{ position: 'relative' }}>
                              <SoftTypography variant="caption">Projekt: {item.id}</SoftTypography>

                              {/*
                              <NavLink
                                style={{
                                  textDecoration: 'underline !important',
                                  color: '#0048B1',
                                  marginRight: '5px',
                                  fontSize: '0.75rem',
                                  fontWeight: '700'
                                }}
                                to={'/projects/' + item.id}
                                className="text-primary"
                                onClick={()=> {
                                  document.querySelectorAll(".deshboard-backdrop").forEach(el => el.remove());
                                }}
                              >{item.id}</NavLink>
                              */}
                            </div>
                          </SoftBox>
                        </div>
                      </div>

                      <SoftTypography variant="h5" fontSize="12px" color="#344767">{item?.salse_person?.name}</SoftTypography>
                      <SoftTypography fontSize="0.75rem" color="#3A416F" style={{ display: 'inline-block', lineHeight: '14px' }} variant="caption">Name: <strong>{item.vorname} {item.name}, {item.plz}, {item.telefonnummer}</strong></SoftTypography>

                      <div className="row mt-3">
                        <div className="col-12">
                          <SoftTypography fontSize="0.75rem" fontWeight="bold">Bewertung</SoftTypography>
                          <ProjectStatus projectid={item?.id} status={item?.status} salesPopup={true}/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>}

            </div>
          </div>
        </div>
      </div> :
        <button onClick={() => setToday(true)} className="right-arrow-btn-2">
          <i className="fas fa-angle-right"></i>
        </button>}

        <button className="d-none" id="dashboardProjectCardListRefresh" onClick={()=> { setRefresh(refresh+1); }}></button>
    </>
  );
}

export default TodaysCardList;
