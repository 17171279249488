import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import callFetch from 'helpers/callFetch';
import Cookies from 'js-cookie';
import React, { useState, useRef, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Timeline from "../create/Timeline";
import Comisioning from "./Comisioning";
import DropZoneTabs from 'components/DropZoneTabs/Index';
import Overview from '../Overview';
import ACabnahmeSign from './ACabnahmeSign';
import ComisioningModal from "./ComisioningModal";

const ACReklmationProtokoll = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const notify = () => toast("Success");
    const signaturePadRef = useRef(null);
    const [link, setLink] = useState(null)
    const [modules, setModules] = useState([]);
    const [id, setID] = useState(0);
    const [tabFiles, setTabFiles] = useState({});
    const params = useParams();
    const [strgItemRefresh, setStrgItemRefresh] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [description, setDescription] = useState("");
    const [message, setMessage] = useState('Success');
    const [color, setColor] = useState('success');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [strgItem, setStrgItem] = useState({ strg_1: '' });
    const [voltItem, setVoltItem] = useState({ strg_1: '' });

    const openSuccessSB = () => setSuccessSB(true);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [0]);

    useEffect(() => {
        
        setID(0);
        reset();
        
        if (props.project_id !== undefined) {
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id=" + props.project_id, "GET", []).then((res) => {
                setValue('modultyp', res?.data?.quations?.modul);
                setValue('modulanzahl', res?.data?.quations?.anzahl_module);

                if (res?.data?.quations?.wechselrichter_eingeben == 'no') {
                    setValue('wechselrichter', res?.data?.quations?.wechselrichter?.name);
                } else {
                    setValue('wechselrichter', res?.data?.quations?.wechselrichter_titel);
                }
            });

            callFetch("ac_reklmation_abhnahmeprotokoll/" + props.project_id + "/edit?reclamation_id="+props.reklamationId, "GET", []).then((res) => {

                //setStrgItem();

                if(res?.data?.strg){
                    setStrgItem(JSON.parse(res?.data?.strg));
                    setVoltItem(JSON.parse(res?.data?.volt));
                }

                if (res?.data?.dcabname) {
                    setTabFiles(JSON.parse(res?.data?.dcabname?.filesdata));
                    setID(res?.data?.dcabname?.id);
                    setValue('modultyp', res?.data?.dcabname?.modultyp)
                    setValue('modulanzahl', res?.data?.dcabname.modulanzahl)
                    setLink(res?.data?.pdf)
                    for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                        if (key == 'filesdata') {
                            setTabFiles(JSON.parse(value));
                        }
                        else {
                            
                            if(key == 'verbaut_ja'){ 
                                if(value == 1){ 
                                    setVerbaut(true);
                                } 
                            }
                            if(key == 'smarter_ja'){ 
                                if(value == 1){ 
                                    setSmarter(true);
                                } 
                            }
                            
                            if(key == 'sls_verbaut_ja'){ 
                                if(value == 1){ 
                                    setSls(true);
                                } 
                            }
                            
                            if(key == 'ausgebaut_ja'){ 
                                if(value == 1){ 
                                    seAusgebaut(true);
                                } 
                            }
                            setValue(key, (value == null || value == 'null' ? "" : value));
                        }
                    }
                    setStrgItemRefresh(strgItemRefresh + 1);
                }
            })
        }
    }, [props.project_id, props.reklamationId, refresh]);

    const setCallBackData = (data) => {
        setTabFiles(data);
    }

    const [verbaut, setVerbaut] = useState();
    const handleVerbaut = (e) => {
        setVerbaut(e.target.checked);
    }

    const [smarter, setSmarter] = useState();
    const handleSmarter = (e) => {
        setSmarter(e.target.checked);
    }

    const [sls, setSls] = useState();
    const handleChangeSLS = (e) => {
        setSls(e.target.checked);
    }

    const [ausgebaut, seAusgebaut] = useState();
    const handleAusgebaut = (e) => {
        seAusgebaut(e.target.checked);
    }

    const onSubmit = (formData) => {
        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');
        // formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.filesdata = JSON.stringify(tabFiles);
        formData.reclamation_id = props.reklamationId;

        if (id >= 1) {

        } else {
            formData.project_id = props.project_id;
            formData.eidt_id = Cookies.get('ac_pdf');
        }

        callFetch((id > 0 ? "ac_reklmation_abhnahmeprotokoll/" + id : "ac_reklmation_abhnahmeprotokoll"), "POST", formData, setError).then((res) => {

            //setData(res);
            //setLink(res?.data?.pdf);

            if (!id) {
                setID(res?.data?.id);

                setTimeout(() => {
                    document.getElementById('ComisioningModalOpen').click();
                    document.getElementById('saveSign').click();
                }, 2000);
            }

            setColor('success')
            setMessage('Success');

            setTimeout(() => {

                setSaving(false);
                openSuccessSB();

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                if (!res.ok) return;
                setSubmitSuccess(true);
                setRefresh(refresh + 1)
                clearForm();

            }, 2000);

        });
    }
    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });
    }
    return (
        <>
            <div className='row mt-3'>
                <div className='col-lg-9'>
                    <Overview className="mb-3" project_id={params?.id} title={t('AC Reklmation Abnahmeprotokoll')} ></Overview>

                    <form id="createOrderProcesing" className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        {
                            link !== null && <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link && link} ></a>
                        }

                        <input type="hidden" id="acid" value={id} />

                        {id > 0 ? <input type="hidden" defaultValue="PUT" {...register("_method")} /> : ''}
                        <SoftBox>
                            <Card sx={{ overflow: "visible" }}>
                                <SoftBox p={3}>
                                    <SoftTypography variant="h5">{t('AC Reklmation Abnahmeprotokoll')}</SoftTypography>
                                </SoftBox>

                                <SoftBox p={2} lineHeight={1}>

                                    <Grid my={2} container spacing={2}>
                                        {
                                            Object.keys(strgItem).map(function (keyName, index) {
                                                return (
                                                    <>
                                                        <Grid item xs={6} sm={6} md={2} lg={2}>
                                                            <label>Strg {(index + 1)}</label>
                                                            <input
                                                                type="text" className="form-control"
                                                                placeholder={"Strg " + (index + 1)}
                                                                /*
                                                                {...register('strg[' + keyName + ']', {
                                                                    required: true
                                                                })}
                                                                */
                                                               value={strgItem[keyName]}
                                                                required
                                                                readOnly
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6} sm={6} md={2} lg={2}>
                                                            <label>Volt {(index + 1)}</label>
                                                            <input
                                                                type="text" className="form-control"
                                                                placeholder={"Volt " + (index + 1)}
                                                                /*
                                                                {...register('volt[' + keyName + ']', {
                                                                    required: true
                                                                })}
                                                                */
                                                                value={voltItem[keyName]}
                                                                required
                                                                readOnly
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                        }
                                        </Grid>

                                        <Grid my={2} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <label>Modultyp (hersteller, typ)</label>
                                                <select className="form-control" {...register('modultyp', { required: true })} required>
                                                    <option>--</option>
                                                    {
                                                        modules && modules.map((modules, index) => {
                                                            return <option key={index} value={modules.id}>{modules.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <label>Anlagenleistung in kwp</label>
                                                <input type="text" className=" form-control" placeholder="Anlagenleistung in kwp" {...register('anlagenleistung', { required: true })} required />
                                            </Grid>
                                        </Grid>

                                        <Grid my={2} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <label>Modulanzahl (parallel × reihe)</label>
                                                <input type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl', { required: true })} required />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <label>Wechselrichter (Hersteller, typ, nennleistung)</label>
                                                <input type="text" className=" form-control" placeholder="Wechselrichter" {...register('wechselrichter', { required: false })} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                                <label>Potenzialausgleich/Erdung</label>
                                                <input type="text" className=" form-control" placeholder="Potenzialausgleich/Erdung" {...register('potenzialausgleich', { required: true })} required />
                                            </Grid>

                                        </Grid>

                                        <Grid my={2} container spacing={2} >
                                             
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Anlagenmontage ohne Sichtbare Schäden?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlagenmontage_ja")}
                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlagenmontage_nein")}
                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Anlagenmontage Sauber hänterlassen?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlagenmontage_sauberja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlagenmontage_sauber_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Anlagenfunktion geprüft und Anlage im Portal angemeldet?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlagenfunktion_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlagenfunktion_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Überspannungsschutz verbaut</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className="d-flex">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("verbaut_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                    onChange={(e) => handleVerbaut(e)}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                            {verbaut && <input  {...register("verbaut_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px', top: '-8px' }} placeholder='Type' />}
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("verbaut_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Kunden eingewiesen</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("kunden_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("kunden_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Anlage auf 0% Prozent Eingespeisung eingestellt</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlage_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("anlage_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Smarter verbaut?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("smarter_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                    onChange={(e) => handleSmarter(e)}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                            {smarter && <input  {...register("smarter_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px' }} placeholder='Type' />}
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("smarter_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >SLS Schalter verbaut?</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("sls_verbaut_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                    onChange={(e) => handleChangeSLS(e)}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                            {sls && <input  {...register("sls_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px' }} placeholder='Enter Type & AMper' />}
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("sls_verbaut_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Zählernummer</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("zahlernummer_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("zahlernummer_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Zähler ausgebaut</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("ausgebaut_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                    onChange={(e) => handleAusgebaut(e)}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                            {ausgebaut && <input  {...register("ausgebaut_text")} className='d-inline-block form-control' style={{ position: 'relative', left: '8px' }} placeholder='Zählernummer' />}
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("ausgebaut_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                                <Grid container spacing={2} >
                                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Aktueller Zählerstand</SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("aktueller_ja")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >JA</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                                        <div className=" d-flex align-items-center">
                                                            <div className="form-check d-inline-block">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register("aktueller_nein")}

                                                                    className="form-check-input  ms-1"
                                                                    value={1}
                                                                />
                                                            </div>
                                                            <label className="mt-2 uppercase" >NEIN</label>
                                                        </div>
                                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </SoftBox>
                            </Card>
                        </SoftBox>

                        <SoftBox my={2}>
                            <Card>
                                <SoftBox p={3}>
                                    <SoftTypography variant="h5">{t('Files')}</SoftTypography>
                                </SoftBox>

                                <SoftBox px={3} pb={3}>
                                    <DropZoneTabs
                                        tabs={[
                                            {
                                                name: 'wechselrichter_und_umgebung',
                                                titel: 'Wechselrichter und Umgebung',
                                            }, {
                                                name: 'anschlussbereich_wechselrichter',
                                                titel: 'Anschlussbereich Wechselrichter',
                                            }, {
                                                name: 'installierte_batterien_und_umgebung',
                                                titel: 'Installierte Batterien und Umgebung',
                                            }, {
                                                name: 'zahlerschrank_geoffneter_zustand',
                                                titel: 'Zählerschrank (geöffneter Zustand)',
                                            }, {
                                                name: 'verbraucher_sls_schalter_nh_sicherung',
                                                titel: 'Verbraucher SLS Schalter (NH Sicherung)',
                                            }, {
                                                name: 'ac_uberspannungsschutz_ac_uss',
                                                titel: 'AC Überspannungsschutz 1&2 (AC USS)',
                                            }, {
                                                name: 'leitungsschutz_des_wechselrichter',
                                                titel: 'Leitungsschutz des Wechselrichter',
                                            }, {
                                                name: 'smart_meter',
                                                titel: 'Smart Meter',
                                            }, {
                                                name: 'pv_Anlage_zahlerschrank',
                                                titel: 'PV Anlage Zählerschrank',
                                            },
                                            {
                                                name: 'werte',
                                                titel: 'Eingestellte Werte',
                                            },
                                            {
                                                name: 'foto_chos_phi',
                                                titel: 'Foto Chos Phi / Länderspezifische Einstellung',
                                            },
                                            {
                                                name: 'foto_einsp',
                                                titel: 'Foto Einspeiserregelung',
                                            },
                                            {
                                                name: 'foto_zahlerstand',
                                                titel: 'Foto Zählerstand zum Zeitpunkt der Anlagenerrichtung',
                                            },
                                            {
                                                name: 'foto_von_der',
                                                titel: 'Foto von der laufenden Anlage',
                                            },
                                            {
                                                name: 'zahlerschrank_mit',
                                                titel: 'Zählerschrank mit Berührungsschutz',
                                            },
                                            {
                                                name: 'zahlerschrank_ohne',
                                                titel: 'Zählerschrank ohne Berührungsschutz',
                                            },
                                        ]}
                                        defaultFiles={tabFiles}
                                        defaultTab={'wechselrichter_und_umgebung'}
                                        callBackData={setCallBackData}
                                    />
                                </SoftBox>
                            </Card>
                        </SoftBox>

                        <ACabnahmeSign saveBtnDisplay={false} flashLoader={false} pdfDisplay={true} project_id={params?.id} reklamationId={props?.reklamationId} id={id} signFrom={'reklamation'} />

                        <SoftBox p={2} display="flex" justifyContent="flex-end">
                            {JSON.parse(Cookies.get('permissions')).indexOf("acAbhU") !== -1 ? (
                                <>
                                    {!saving && (
                                        <button type="submit" id="acAbname" className="btn btn-primary d-none">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled d-none" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </>
                            ) : <></>}
                        </SoftBox>
                    </form>

                </div>
                <div className="col-lg-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {props.project_id ? props.project_id : '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <Comisioning projectid={props.project_id} id={id} type={'ac'}></Comisioning> */}

                    <ComisioningModal reklamationId={props?.reklamationId} abnahme={'reklmation'} getdataUrl={'ac_reklmation_abhnahmeprotokoll/'} saveDataUrl={''} projectid={props.project_id} id={id} type={'ac'} setRefresh={setRefresh} />

                    <Timeline refresh={refresh} acStatusEdit={true}/>
                </div>
            </div>


        </>
    )
}

export default ACReklmationProtokoll