import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
const DCFiles = (props) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const [dataa, setDataa] = useState([]);
  const [dachansicht, setDachansicht] = useState([]);
  const [attachments, setAttachment] = useState([]);
  const [ref, setRef] = useState(0);
  const showAlert = (id, sectionKey) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedData = { ...dataa }; // Create a copy of the data object
          const sectionData = updatedData[sectionKey]; // Get the data array for the section
          const filteredSectionData = sectionData.filter((value) => value.id !== id); // Remove the item with the given id
          updatedData[sectionKey] = filteredSectionData; // Update the section data in the copied object
          setDataa(updatedData); // Update the state with the modified data 

          var formData = new FormData();
          formData.attachments = JSON.stringify(updatedData);
          formData.id = id;
          formData.project_id = props.project_id;
          callFetch("update-dc-files", "POST", formData).then((res) => {
            setRef(ref + 1);
          });

          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
  };
  const removeAttachment = (id, sectionKey) => {
    showAlert(id, sectionKey);
  }

  useEffect(() => {
    callFetch("get-dc-uploaded-files/" + props.project_id, "GET", []).then((res) => {
      setDataa(JSON.parse(res.filesdata));
    });
  }, [props.project_id, props.refresh]);
  return (
    <>
      <div className="row mb-5">
        <SoftBox p={3} className="order-processing">
          <Grid container spacing={3}>
            {Object.keys(dataa).map((sectionKey) => (
              <>
                {dataa[sectionKey].map((imageData, index) => (
                  <Grid item key={index}>
                    <a target="_blank" rel="noopener noreferrer" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + imageData.attachment} >
                      <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + imageData.attachment} alt={imageData.name} />
                    </a>
                    <br />
                    <a onClick={() => { removeAttachment(imageData.id, sectionKey); }} className="d-block text-center mt-2" > {t('Remove')} </a>
                  </Grid>
                ))}
              </>
            ))}
          </Grid>
        </SoftBox>
      </div>
      <ToastContainer />
    </>
  )
}

export default DCFiles