import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import { useForm } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function FilesIndexTable(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [selectedFilesRefresh, setSelectedFilesRefresh] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (props?.checkForEmail && props?.checkForEmail == true && props?.onlyCustomFiles == 1) {
            props?.setSelectedFiles(selectedFiles);
            props?.setSelectedFilesRefresh(selectedFilesRefresh);
        }        
    }, [selectedFilesRefresh]);


    const tableHeadings = [];
    if (props?.checkForEmail && props?.checkForEmail == true && props?.onlyCustomFiles == 1) {
        tableHeadings.push({
            name: t('Organisation'),
            selector: row => (
                <SoftBox display="flex" alignItems="center">
                    <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                var add_id = selectedFiles;
                                add_id.push(row?.id)
                            } else {
                                var remove_id = selectedFiles;
                                const index = remove_id.indexOf(row?.id);
                                if (index > -1) {
                                    remove_id.splice(index, 1);
                                    setSelectedFiles(remove_id);
                                }
                            }
                            setSelectedFilesRefresh(selectedFilesRefresh+1)
                        }}
                    //defaultChecked={false}
                    />
                    <SoftBox ml={1}>
                        <SoftTypography variant="caption" fontWeight="medium" color="text">
                            <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + row.pdflink} style={{ color: '#0048B1', textDecoration: 'underline', fontWeight: '500' }}>{row.title}</a>
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            )
        });
    } else {
        tableHeadings.push({
            name: t('Organisation'),
            selector: row => (
                <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + row.pdflink} style={{ color: '#0048B1', textDecoration: 'underline', fontWeight: '500' }}>{row.title}</a>
            ),
            sortable: true,
        });
    }

    tableHeadings.push({
        name: t('Date'),
        selector: row => (
            <span style={{ color: '#718096', fontWeight: '500' }}>{dateFormat(row.created_at, "dd.mm.yyyy")}</span>
        )
    });

    tableHeadings.push({
        name: t('Erstellt von:'),
        cell: row => (<span style={{ color: '#718096', fontWeight: '400' }}>{row.name}</span>)
    });

    tableHeadings.push({
        name: t('Action'),
        selector: row => (<a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + row.pdflink} style={{ color: '#0048B1', textDecoration: 'underline', fontWeight: '500' }}>Download</a>)
    });
    
    /*
    if (props?.status && props?.status == true && props?.onlyCustomFiles == 1) {
        tableHeadings.push({
            name: t('Status'),
            selector: row => (
                <>
                    <button
                        className={'btn btn-sm my-1 ' + (row?.status == 0 ? 'btn-primary' : 'btn-success')}
                        onClick={() => {
                            var formData = {};
                            formData.id = row?.id;
                            formData.status = (row?.status == 0 ? 1 : 0);
                            callFetch("custom-file-update", "POST", formData, setError).then((res) => {
                                setRefresh(refresh + 1);
                            });
                        }}
                    >{row?.status == 0 ? t('Approve') : t('Approved')}</button>
                </>
            )
        });
    }
    */

    useEffect(() => {
        callFetch("project-files/" + params.id + "?page=" + pageNumber + '&onlyCustomFiles=' + (props?.onlyCustomFiles ? props?.onlyCustomFiles : 0)+'&for='+props?.for, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh, props?.refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default FilesIndexTable;
