import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import EmailForm from "./EmailFormInvoice";
import Invoice from "./Invoice";
import SoftAlert from "components/SoftAlert";
import Overview from "pages/project/Overview";
import Timeline from "../Timeline";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AllInvoices from 'pages/finance/Invoice/IndexTable';
import AllPayments from 'pages/finance/Payment/IndexTable';
import CreateInvoice from 'pages/finance/Invoice/Create';
import IncomingInvoiceIndexTable from 'pages/finance/EngangsInvoice/IncomingInvoiceIndexTable';
import Transactions from 'pages/finance/EngangsInvoice/Transactions';


function Invoices(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [update, setUpdate] = useState(0);
    const [adding, setAdding] = useState(false);
    const [data, setData] = useState([]); 
    const [invoicePercenage, setInvoicePercenage] = useState([]);
    const [totalInvoicePercenage, setTotalInvoicePercenage] = useState(0);
    const [loadInv, setLoadInv] = useState(0); 
    const [receivedError, setReceivedError] = useState(null);
    const [canCreateInvoice, setCanCreateInvoice] = useState(true);


    useEffect(() => {
        if (!params.id) return; 
        callFetch("project/invoice/create/" + params.id, "GET", []).then((res) => {
            if (res?.data?.quations?.order?.id) {
                setData(res?.data);
            } else {
                toast(t("Fill Out Order"));
                document.getElementById('auftrag').click();
                return;
            }

        });
    }, [params.id]);


    /*
    useEffect(() => {
        if (!data.id) return;

        callFetch("get-invoices/" + data?.quations?.order?.id, "GET", []).then((res) => {
            setInvoices(res.invoices);
            setInvoicePercenage(res.invoicePercenage);
            setTotalInvoicePercenage(res.totalInvoicePercenage);
            setSaving(false);
        });
    }, [data, loadInv, update]);
    */

    /*
    const addInvoice = () => {
        setAdding(true);
        callFetch("add-invoice/" + data?.quations?.order?.id, "GET", []).then((res) => {
            setAdding(false);

            if (res.status == 'error') {
                setReceivedError(t(res.message));
            } else {
                setLoadInv(loadInv + 1);
            }
        });
    }
    */

    return (
        <>
            <div className="row">
                <div className="col-sm-9">
                    <Overview project_id={params?.id} title={t("Invoice")} />

                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>
                                        {t('All Invoices')}
                                    </h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <AllInvoices displayCreateBtn={true} canCreateInvoice={canCreateInvoice} projectId={params?.id} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>{t('All Payments')}</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <AllPayments canCreatePayment={true} projectId={params?.id} filter={'All'} editPopup={true} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>{t('Eingangsrechnungen')}</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <IncomingInvoiceIndexTable canCreate={true} projectId={params?.id} filter={'All'} editPopup={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*
                <div className="mb-4 mt-3">
                    <div className="">
                        {Array.isArray(invoices) && !invoices.length ? <Invoice paymentOption={data?.quations?.payment_option} totalInvoicePercenage={totalInvoicePercenage} invoicePercenage={invoicePercenage} projectid={params?.id} quationId={data?.quations?.id} orderId={data?.quations?.order?.id} setLoadInv={setLoadInv} isUpdate={update} /> : ''}
                        {invoices && invoices.map((inv) =>
                            <Invoice paymentOption={data?.quations?.payment_option} totalInvoicePercenage={totalInvoicePercenage} invoicePercenage={invoicePercenage} projectid={params?.id} id={inv.id} quationId={data?.quations?.id} orderId={data?.quations?.order?.id} isUpdate={update} setLoadInv={setLoadInv} />
                        )}
                        <div className="row mt-1">
                            {receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert>}
                            <div className="col-md-12">
                                {!saving && (
                                    <button type="button" className="btn btn-primary" onClick={() => { setUpdate(update + 1); setSaving(true); setLoadInv(loadInv + 1) }}>
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                                {!adding ? (
                                    data?.quations?.payment_option == '100' ? <button type="button"
                                    className="btn btn-disabled"
                                    //disabled={totalInvoicePercenage < 100 ? false : true}
                                    onClick={() => {
                                         toast(t("Inovice has created for receivable amount.")); 
                                    }}
                                    style={{ marginLeft: '5px' }}>
                                    {t('Add Invoice')}
                                </button> : <button type="button"
                                        className={totalInvoicePercenage < 100 ? "btn btn-primary" : "btn btn-disabled"}
                                        //disabled={totalInvoicePercenage < 100 ? false : true}
                                        onClick={() => {
                                            if (totalInvoicePercenage < 100) {
                                                addInvoice();
                                            } else {
                                                toast(t("Inovice has created for receivable amount."));
                                            }
                                        }}
                                        style={{ marginLeft: '5px' }}>
                                        {t('Add Invoice')}
                                    </button>

                                ) : (
                                    <button type="button" className="btn btn-disabled" disabled style={{ marginLeft: '5px' }}>
                                        {t('Creating...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                */}

                </div>
                <div className="col-sm-3">
                    {params?.id ? (
                        <Transactions projectId={params?.id} />
                    ) : ''}

                    <div className="card mb-4 mt-4">
                        <div className="card-header pb-0">
                            <h6>{t('Email')}</h6>
                        </div>
                        <div className="card-body">
                            <EmailForm id={params.id} pdf={true} orderID={data?.quations?.order?.id ? data?.quations?.order?.id : 0}/>
                        </div>
                    </div>

                    <Timeline refresh={loadInv} />
                </div>
            </div>

            <div className="modal fade" id="createBuchhaltung" tabindex="-1" role="dialog" aria-labelledby="createBuchhaltungLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h6 className="modal-title" id="createBuchhaltungLabel" style={{ fontWeight: 'bold' }}></h6>
                            <button
                                type="button"
                                id="createBuchhaltungClose"
                                className="btn-close text-dark"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <CreateInvoice
                                orderId={data?.quations?.order?.id ? data?.quations?.order?.id : 0}
                                col="col-md-12"
                                redirect={false}
                                popupClose={true}
                                invoiceListRefresh={true}
                                ZahlungseingangeListRefresh={true}
                                canCreateInvoice={setCanCreateInvoice}
                            />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Invoices;
