import { useMemo, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { GoogleMap, useLoadScript, Rectangle, Marker, Polygon, OverlayView, useGoogleMap } from '@react-google-maps/api';
import { column } from "stylis";
import select from "assets/theme/components/form/select";


const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
})

function millimetersToPixels(millimeters, latitude, zoom) {
    var meters = millimeters * 0.001;
    const earthCircumference = 40075016.686; // Earth's circumference in meters at the equator
    const metersPerPixel = earthCircumference * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom + 8);
    return meters / metersPerPixel;
}

function calculateDegree(lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const angleRad = Math.atan2(y, x);
    let angleDeg = angleRad * (180 / Math.PI);

    if (angleDeg < 0) {
        angleDeg += 360; // Ensure positive degrees (0 to 360)
    }

    return angleDeg;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Earth's radius in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c; // Distance in kilometers

    return distance;
}

function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}

function RoofPlannerV3(props) {
    let params = useParams();

    const [drawing, setDrawing] = useState(false);
    const [boudsSelected, setBoudsSelected] = useState(false);
    const [rotateing, setRotateing] = useState(false);
    const [editPanelGroupIndex, setEditPanelGroupIndex] = useState('');
    const [moving, setMoving] = useState(false);

    const [refresh, setRefresh] = useState(0);
    const [startPoint, setStartPoint] = useState(null);
    const [endPoint, setEndPoint] = useState(null);
    const [totalLeftRactangle, setTotalLeftRactangle] = useState(0);
    const [totalRightRactangle, setTotalRightRactangle] = useState(0);
    const [totalTopRactangle, setTotalTopRactangle] = useState(0);
    const [totalBottomRactangle, setTotalBottomRactangle] = useState(0);
    const [width, setWidth] = useState(998); //mm
    const [height, setHeight] = useState(1690); //mm
    const [widthMetersToLatitude, setWidthMetersToLatitude] = useState(((width * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters
    const [heightMetersToLatitude, setHeightMetersToLatitude] = useState(((height * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBuEcSXUNCT3f9WrObXFVEKr7DDJu64MpU',
        libraries: ["places"]
    });

    const [bounds, setBounds] = useState([]);
    const [panelGroups, setPanelGroups] = useState([]);
    const [mapZoom, setMapZoom] = useState(21);
    const [mapCenter, setMapCenter] = useState({ lat: 37.44635467220939, lng: -122.13860325160785 });
    const [position, setPosition] = useState({ lat: 37.44635467220939, lng: -122.13860325160785 });

    const [clickPosition, setClickPosition] = useState(null);
    const [selectedPoints, setSelectedPoints] = useState([]);

    const [rows, setRows] = useState(0);
    const [columns, setColumns] = useState(0);
    const [angle, setAngel] = useState(0);


    function handleDrag(e) {
        console.log("X: " + e.clientX + " | Y: " + e.clientY)
    }

    const addPanels = () => {
        setDrawing(true);
    }

    const mapRef = useRef(null);

    function handleLoad(map) {
        mapRef.current = map;
    }

    function handleCenterChanged() {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setPosition(newPos);
        //console.log('handleCenterChanged')
    }

    function handleZoomChanged() {
        console.log(this.getZoom()) //this refers to Google Map instance
        setMapZoom(this.getZoom());
    }

    /*
    const handleMouseUp = () => {
        console.log('handleMouseUp');
        //setDrawing(false);
    };
    */

    const generateBonds = () => {
        /*
        var currentBounds = [];
        currentBounds.push({
            north: latLng?.lat + heightMetersToLatitude,
            south: latLng?.lat - heightMetersToLatitude,
            east: latLng?.lng + widthMetersToLatitude,
            west: latLng?.lng - widthMetersToLatitude,
        });
        setBounds(currentBounds);
        */
    }

    const handleMarkerDrag = (index, newPosition) => {
        const updatedPoints = [...selectedPoints];
        updatedPoints[index] = newPosition.toJSON();
        setSelectedPoints(updatedPoints);

        if (selectedPoints.length === 4) {
            generateRectangleBounds();
        }

        setRefresh(refresh + 1)
    };

    const removeMarker = (index) => {
        const updatedPoints = [...selectedPoints];
        updatedPoints.splice(index, 1);
        setSelectedPoints(updatedPoints);
        setRefresh(refresh + 1)
    };

    const calculateShapeCoords = () => {
        return selectedPoints;
    };

    const calculateMaximumRectangles = () => {
        // Calculate the area of the shape formed by selected points
        // For demonstration purposes, let's assume the shape area calculation
        const shapeArea = calculateShapeArea(selectedPoints);

        // Calculate area of single rectangle (1035mm width * 2500mm height)
        const rectangleArea = (width * height) * 2;
        console.log('eachModule=' + rectangleArea)

        // Calculate the maximum number of rectangles that can fit within the shape
        return Math.floor(shapeArea / rectangleArea);
    };

    const calculateShapeArea = (points) => {
        const EARTH_RADIUS = 6371000; // Earth's radius in meters

        const radians = (degrees) => {
            return degrees * (Math.PI / 180);
        };

        const getDistance = (lat1, lng1, lat2, lng2) => {
            const dLat = radians(lat2 - lat1);
            const dLng = radians(lng2 - lng1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(radians(lat1)) *
                Math.cos(radians(lat2)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return EARTH_RADIUS * c;
        };

        // Calculate distances between the points
        const distances = [];
        for (let i = 0; i < points.length; i++) {
            const nextIndex = (i + 1) % points.length;
            const distance = getDistance(
                points[i].lat,
                points[i].lng,
                points[nextIndex].lat,
                points[nextIndex].lng
            );
            distances.push(distance);
        }

        // Calculate area using shoelace formula based on distances
        let area = 0;
        for (let i = 0; i < points.length; i++) {
            const nextIndex = (i + 1) % points.length;
            area += distances[i] * distances[nextIndex];
        }

        console.log('calculateShapeArea=' + (Math.abs(area / 2) * 1000000))

        return Math.abs(area / 2) * 1000000; // Convert to square millimeters
    };

    const handleMapClick = (event) => {


        if (selectedPoints.length < 4) {
            setSelectedPoints([...selectedPoints, event.latLng.toJSON()]);
        }

        setRefresh(refresh + 1)
        console.log(selectedPoints)
        return;


        console.log('handleMapClick');

        setClickPosition(event.latLng);

        if (drawing && boudsSelected === false && moving === false && rotateing === false) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON

            setStartPoint(latLng);
            var currentBounds = bounds;
            var currentpanelGroups = panelGroups;
            currentBounds.push({
                north: latLng?.lat + heightMetersToLatitude,
                south: latLng?.lat - heightMetersToLatitude,
                east: latLng?.lng + widthMetersToLatitude,
                west: latLng?.lng - widthMetersToLatitude,
            });

            currentpanelGroups.push({
                position: { lat: latLng?.lat + (heightMetersToLatitude * 2), lng: latLng?.lng - (widthMetersToLatitude * 2) },
                angle: 0,
            });

            setBounds(currentBounds);
            setPanelGroups(currentpanelGroups);
            setBoudsSelected(true);
            setRefresh(refresh + 1)

        } else {
            setBoudsSelected(false);
        }
        setDrawing(false);
        setMoving(false);
        setRotateing(false);
    };


    const handleMouseMove = (event) => {

        //console.log('drawing='+drawing)
        //console.log('moving='+moving)
        //console.log('rotateing='+rotateing)

        if (moving && drawing) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON

            var currentpanelGroups = panelGroups;
            currentpanelGroups[editPanelGroupIndex].position = { lat: latLng?.lat + (heightMetersToLatitude * 2), lng: latLng?.lng - (widthMetersToLatitude * 2), }
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1);
        } else if (rotateing && drawing) {
            //console.log('rotateing...');

            /*
            var currentpanelGroups = panelGroups;
            const latLng = event.latLng && event.latLng.toJSON(); // Get the current mouse point LatLng as JSON


            const angle = calculateDegree(
                ((currentpanelGroups[editPanelGroupIndex].position.lat) * Math.PI) / 180,
                ((currentpanelGroups[editPanelGroupIndex].position.lng) * Math.PI) / 180,
                (latLng.lat * Math.PI) / 180,
                (latLng.lng * Math.PI) / 180
            );

            currentpanelGroups[editPanelGroupIndex].angle = angle;
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1);
            */

            const latLng = event.latLng && event.latLng.toJSON(); // Get the current mouse point LatLng as JSON
            const angle = calculateDegree(
                ((selectedPoints[0].position.lat) * Math.PI) / 180,
                ((selectedPoints[0].position.lng) * Math.PI) / 180,
                (latLng.lat * Math.PI) / 180,
                (latLng.lng * Math.PI) / 180
            );

            setAngel(angle);

            console.log(angle)
        } else {
            //console.log('handleMouseMove');
            //console.log('moving='+moving);
            // console.log('drawing='+drawing);
        }



        /*
        if (drawing && boudsSelected === false) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON
            setEndPoint(latLng);
            //console.log('handleMouseMove drawing')

            console.log('handleMouseMove drawing');
        }
        */
    };

    const singleRectangle = (
        bound,
        options = {
            strokeColor: '#FFFFFF',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: '#000000',
            fillOpacity: 0,
        },
        action = 'add'
    ) => {

        options.bounds = bound;
        options.fixedRotation = true;
        options.rotation = -30;

        console.log(bound.north - bound.south)

        return (
            <>
                <Rectangle
                    //Top Ractangle
                    //key={`${i}-${j}`}
                    //bounds={bound}
                    options={options}
                    onClick={(e) => {
                        console.log(action);
                        //setTotalTopRactangle(totalTopRactangle + 1);
                        var currentBounds = bounds;
                        currentBounds.push(bound);
                        setBounds(currentBounds);
                        setRefresh(refresh + 1)

                    }}
                />

                {/*
                <OverlayView
                    position={{ lat: bound.north, lng: bound.west }}
                    mapPaneName={OverlayView.OVERLAY_LAYER}
                    getPixelPositionOffset={(width, height) => ({ x: -width / 2, y: -height / 2 })}
                    onClick={(e) => {
                        console.log(action);
                        //setTotalTopRactangle(totalTopRactangle + 1);
                        var currentBounds = bounds;
                        currentBounds.push(bound);
                        setBounds(currentBounds);
                        setRefresh(refresh + 1)

                    }}
                >
                    <div
                        className="panel"
                        style={{
                            //width: `${Math.abs(bound.east - bound.west)}px`,
                            //height: `${Math.abs(bound.north - bound.south)}px`,
                            width: `${(millimetersToPixels(width, bound.north, mapZoom) - 1)}px`,
                            height: `${(millimetersToPixels(height, bound.north, mapZoom)) - 1}px`,
                            // backgroundColor: options?.fillColor ? options?.fillColor : '',
                            border: ('1px solid ' + (options?.strokeColor ? options?.strokeColor : '')),
                            transform: `rotate(${(0)}deg)`,
                            //opacity: options?.fillOpacity ? options?.fillOpacity : 0,
                            //position: 'absolute',
                            //transformOrigin: 'center center',
                        }}
                        ref={(div) => {
                            if (div) {
                                //rotateRectangles(props.projection, div, rect.rotation);
                            }
                        }}
                    >
                        <div
                            className="panel"
                            style={{
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer'
                            }}

                        >
                        </div>
                    </div>
                </OverlayView>
            */}
            </>
        );
    }


    const generateRectangles = (numberOfRectangles) => {
        const rectangles = [];
        //const numberOfRectangles = 10;

        const latStep = (bounds.north - bounds.south) / numberOfRectangles;
        const lngStep = (bounds.east - bounds.west) / numberOfRectangles;

        for (let i = 0; i < numberOfRectangles; i++) {
            const north = bounds.north - i * latStep;
            const south = bounds.north - (i + 1) * latStep;
            for (let j = 0; j < numberOfRectangles; j++) {
                const west = bounds.west + j * lngStep;
                const east = bounds.west + (j + 1) * lngStep;
                rectangles.push(
                    <Rectangle
                        key={`${i}-${j}`}
                        bounds={{ north, south, east, west }}
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                        }}
                    />
                );
            }
        }
        return rectangles;
    };

    const insideContentAngels = (topLeft, topRight, bottomRight, bottomLeft, contentWidth, contentHight) => {

        // Get the width and height of the selected area (rectangle formed by the four points)
        const width = Math.sqrt(Math.pow(topRight.lat - topLeft.lat, 2) + Math.pow(topRight.lng - topLeft.lng, 2));
        const height = Math.sqrt(Math.pow(bottomRight.lat - topRight.lat, 2) + Math.pow(bottomRight.lng - topRight.lng, 2));

        // Replace with the actual width and height of your text
        const textWidth = 100;
        const textHeight = 30;

        // Calculate the angle needed to fit the text within the selected area
        const angleWidth = Math.atan(contentWidth / height); // Angle for width
        const angleHeight = Math.atan(contentHight / width); // Angle for height
        console.log('contentWidth=' + contentWidth)

        // Determine the final rotation angle
        const rotationAngle = Math.max(angleWidth, angleHeight) * (360 / Math.PI); // Convert radians to degrees

        return rotationAngle;
    }

    const generateRectangleBounds = () => {
        const shapeArea = calculateShapeArea(selectedPoints);
        const maxRectangles = calculateMaximumRectangles();

        // Assuming rectangles are placed side by side horizontally

        // Calculate the maximum number of rectangles that fit within the shape
        //const maxRectanglesArea = ((width * height) * 2) * maxRectangles;
        //const actualRectanglesCount = Math.min(maxRectangles, Math.floor(shapeArea / maxRectanglesArea));
        const actualRectanglesCount = maxRectangles;

        console.log('actualRectanglesCount=' + actualRectanglesCount)

        // Calculate the bounds for each rectangle
        const bounds = [];
        const latLngDistance = 0.000009; // Example distance for demonstration purposes; adjust as needed

        //console.log(widthMetersToLatitude);



        const angle = calculateDegree(
            ((selectedPoints[0].lat) * Math.PI) / 180,
            ((selectedPoints[0].lng) * Math.PI) / 180,
            (selectedPoints[1].lat * Math.PI) / 180,
            (selectedPoints[1].lng * Math.PI) / 180
        );

        console.log('angle=' + angle)
        //setAngel((angle/4))
        setAngel(0)

        const lat1 = selectedPoints[0].lat; /* Latitude of point 1 */;
        const lon1 = selectedPoints[0].lng /* Longitude of point 1 */;
        const lat2 = selectedPoints[1].lat /* Latitude of point 2 */;
        const lon2 = selectedPoints[1].lng /* Longitude of point 2 */;
        const lat3 = selectedPoints[2].lat
        const lon3 = selectedPoints[2].lng
        const lat4 = selectedPoints[3].lat
        const lon4 = selectedPoints[3].lng

        const distanceTop = calculateDistance(lat1, lon1, lat2, lon2);
        const distanceBottom = calculateDistance(lat3, lon3, lat4, lon4);
        const distanceLeft = calculateDistance(lat1, lat1, lat3, lon3);
        const distanceRight = calculateDistance(lat2, lon2, lat3, lon3);


        // Determine the maximum rows and columns that fit within the selected points
        const widthInDegrees = Math.abs(selectedPoints[0].lng - selectedPoints[1].lng);
        const heightInDegrees = Math.abs(selectedPoints[0].lat - selectedPoints[3].lat);

        var columns = Math.min(Math.floor(widthInDegrees / (widthMetersToLatitude * 2)), actualRectanglesCount);
        while (width * columns > distanceTop * 1000000 || width * columns > distanceBottom * 1000000) {
            --columns;
        }
        var rows = Math.min(Math.floor(heightInDegrees / (heightMetersToLatitude * 2)), Math.ceil(actualRectanglesCount / columns));

        while (height * rows > distanceLeft * 1000000 || height * rows > distanceRight * 1000000) {
            --rows;
        }

        console.log('totalWidth=' + (width * columns))
        console.log('distance=' + (distanceTop * 1000000))

        setRows(rows);
        setColumns(columns);

        //const angle = insideContentAngels(selectedPoints[0], selectedPoints[1], selectedPoints[2], selectedPoints[3], width * columns, height * rows);
        // console.log('insideContentAngels='+angle)
        //setAngel(angle)

        console.log('rows=' + rows);
        console.log('columns=' + columns);

        // Generate bounds for rectangles within the selected points
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
                const north = selectedPoints[0].lat - i * (heightMetersToLatitude * 2);
                const south = north - (heightMetersToLatitude * 2);
                const west = selectedPoints[0].lng + j * (widthMetersToLatitude * 2);
                const east = west + (widthMetersToLatitude * 2);

                // Check if the generated bounds are within the selected points
                if (north <= selectedPoints[0].lat && south >= selectedPoints[3].lat &&
                    west >= selectedPoints[0].lng && east <= selectedPoints[1].lng) {
                    bounds.push({ north, south, east, west });
                }
            }
        }


        setBounds(bounds);
    };


    const memoizedMap = useMemo(() => {

        return (
            <GoogleMap
                id='project-location'
                mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '7px', position: 'relative' }}
                mapContainerClassName={'googleMapContainer'}
                zoom={mapZoom}
                center={mapCenter}
                options={{
                    disableDefaultUI: true,
                    zoomControl: (drawing ? false : true),
                    draggable: (drawing ? false : true),
                    mapTypeId: 'satellite',
                    tilt: 0, // Set tilt to 0 for a top-down view
                    heading: 0, // Set heading to 0 for a top-down view
                }}
                onLoad={handleLoad}
                onClick={handleMapClick}
                onMouseMove={handleMouseMove}
                //onMouseUp={handleMouseUp}
                onCenterChanged={handleCenterChanged}
                onZoomChanged={handleZoomChanged}
                onMouse
            >
                {selectedPoints.map((point, index) => (
                    <Marker
                        key={index}
                        position={point}
                        draggable={true}
                        onDrag={(event) => handleMarkerDrag(index, event.latLng)}
                        onClick={() => removeMarker(index)}
                    />
                ))}
                {selectedPoints.length === 4 && (
                    <Polygon
                        paths={[calculateShapeCoords()]}
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                        }}

                        onClick={() => {
                            console.log('Polygon click')
                        }}
                    />
                )}

                {selectedPoints.length === 4 && rows && columns && (
                    <OverlayView
                        position={{ lat: selectedPoints[0].lat, lng: selectedPoints[0].lng }}
                        mapPaneName={OverlayView.OVERLAY_LAYER}
                        getPixelPositionOffset={getPixelPositionOffset}
                        on
                        draggable={true}
                    >
                        <div>
                            <div
                                className="solar-panels"
                                style={{
                                    //width: `${Math.abs(bound.east - bound.west)}px`,
                                    //height: `${Math.abs(bound.north - bound.south)}px`,
                                    width: `${(millimetersToPixels(columns * width, bounds[0].north, mapZoom) - 1)}px`,
                                    height: `${(millimetersToPixels(rows * height, bounds[0].north, mapZoom)) - 1}px`,
                                    // backgroundColor: options?.fillColor ? options?.fillColor : '',
                                    //border: ('1px solid '),
                                    // transform: `rotate(${(angle)}deg)`,
                                    transform: `translate(-0%, -0%) rotate(${angle}deg)`,
                                    //opacity: options?.fillOpacity ? options?.fillOpacity : 0,
                                    //position: 'absolute',
                                    //transformOrigin: 'center center',
                                }}
                            >
                                <table
                                    style={{
                                        border: 'none',
                                    }}>
                                    {[...Array(rows)].map((x, i) =>
                                        <tr>
                                            {[...Array(columns)].map((x, i) =>
                                                <td
                                                    style={{
                                                        width: `${(millimetersToPixels(width, position?.lat, mapZoom) - 1)}px`,
                                                        height: `${(millimetersToPixels(height, position?.lat, mapZoom) - 1)}px`,
                                                        backgroundColor: '#000000', // Example color
                                                        border: '1px solid #ffffff',
                                                    }}
                                                ></td>
                                            )}
                                        </tr>
                                    )}
                                </table>
                            </div>
                        </div>
                    </OverlayView>
                )}
            </GoogleMap>
        );
    }, [refresh, mapZoom, width, height, bounds, bounds.length, drawing, boudsSelected]);


    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-body p-3" style={{ height: 'calc(100vh - 20px)' }}>
                            {memoizedMap}

                            <div style={{
                                position: 'absolute',
                                top: '25px',
                                left: '25px',
                                zIndex: 1000, // Ensure it's above the map
                                width: '420px'
                            }}>
                                <div className='row' style={{ height: '0px' }}>
                                    <div className='col-9'>
                                        <div className='card'>
                                            <div className='card-header p-2 border'>
                                                <h4 className="m-0">Panels System</h4>
                                            </div>
                                            <div className='card-body p-2'>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">{'Orientation'}</label>
                                                            <select
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    if (e.target.value == 'Portrait') {
                                                                        setWidth(998); //mm
                                                                        setHeight(1690); //mm
                                                                        setWidthMetersToLatitude(((998 * 0.001) / 2) / 111000)
                                                                        setHeightMetersToLatitude(((1690 * 0.001) / 2) / 111000)
                                                                    } else {
                                                                        setWidth(1690); //mm
                                                                        setHeight(998); //mm
                                                                        setWidthMetersToLatitude(((1690 * 0.001) / 2) / 111000)
                                                                        setHeightMetersToLatitude(((998 * 0.001) / 2) / 111000)
                                                                    }
                                                                    setRefresh(refresh + 1)
                                                                    console.log(e.target.value)
                                                                }}
                                                            >
                                                                <option value={"Portrait"}>Portrait</option>
                                                                <option value={"Landscape"}>Landscape</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='col-3'
                                        style={{ height: 'fit-content', width: 'auto' }}
                                    >
                                        <button
                                            type='button'
                                            className='btn btn-primary btn-sm m-0 px-2'
                                            onClick={() => { addPanels() }}
                                        >
                                            <i className='fa fa-plus'></i> <br />
                                            Panels
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default RoofPlannerV3