import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSnackbar from "components/SoftSnackbar";

function EmailForm(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [emailText, setEmailText] = useState('');
    const [type, setType] = useState(props.type);
    const [id, setId] = useState(props.id);
    const [subject, setSubject] = useState('');
    const modal = useRef();
    const [errorSB, setErrorSB] = useState(false);
    const closeErrorSB = () => setErrorSB(false);

    const [errorSBOrder, setErrorSBOrder] = useState(false);
    const closeErrorSBOrder = () => setErrorSBOrder(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        unregister,
        formState: { errors },
    } = useForm();



    const onSubmit = (formData) => {
        setSaving(true);
        formData.description = emailText;
        formData.subject = subject;
        formData.project_id = props.projectid;
        if (props?.pdf) {
            callFetch(props.type == 'angebot' ? "quation/" + props.id + '?pdf_generate'
                : props.type == 'auftrag' ? "order/" + props.id + '?pdf_generate'
                    : '',
                "GET", []).then((res) => {
                    if (res?.message == 'success') {
                        formData.pdf = true;
                        setSaving(true);
                        callFetch("sendemail", "POST", formData, setError).then((res) => {
                            setSaving(false);
                            document.getElementById('modalClose').click();
                            if (!res.ok) return;
                            setSubmitSuccess(true);
                        });
                    } else {
                        setSaving(false);
                    }
                    //setSubmitSuccess(true);
                })
        } else {

            if (props?.type == 'fertigmeldung_attchments') {
                formData.attachment_ids = JSON.stringify(props?.attachments);
            } else {
                formData.pdf = false;
            }

            callFetch("sendemail", "POST", formData, setError).then((res) => {
                setSaving(false);
                document.getElementById('modalClose').click();
                if (!res.ok) return;
                if (props?.type == 'fertigmeldung_attchments') {
                    document.getElementById('timeline-refresh-btn').click();
                }
                setSubmitSuccess(true);
            });
        }
    };

    const addEmail = () => {
        const total = totalEmails + 1;
        setTotalEmails(total);
    }
    const removeEmail = () => {
        const i = totalEmails - 1;
        const total = i;
        unregister("email[" + i + "]");
        setTotalEmails(total);
    }

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Create quation first')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const renderErrorSBOrder = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Create order first')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSBOrder}
            onClose={() => closeErrorSBOrder(false)}
            close={() => closeErrorSBOrder(false)}
            bgSuccess
        />
    );

    const beforeSendMail = () => {
        if (type == 'angebot') {
            if (props?.netto > 0) {
                document.getElementById('emailPopUp').click();
                callFetch("get-smtp-email-configaration/" + type, "GET", []).then((res) => {
                    setEmailText(res?.details?.description);
                    setSubject(res?.details?.subject);
                });
            } else {
                setErrorSB(true);
            }
        } else if (type == 'auftrag') {
            if (props?.id > 0) {
                document.getElementById('emailPopUp').click();
                callFetch("get-smtp-email-configaration/" + type, "GET", []).then((res) => {
                    setEmailText(res?.details?.description);
                    setSubject(res?.details?.subject);
                });
            } else {
                setErrorSBOrder(true);
            }
        }else{
            document.getElementById('sendMail').click(); setSaving(true)
        }
    }

    useEffect(() => {
        setValue('type_id', props?.id);
        setValue('type', props?.type);
    }, [props, (props?.selectedFilesRefresh ? props?.selectedFilesRefresh : 0)]);

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register("type_id")} />
                <div className="form-group">
                    <label>{t('Angebot/Auftrag')} *</label>
                    <select
                        className="form-control"
                        {...register("type", {
                            required: true,
                        })}
                        required onChange={(e) => setType(e.target.value)}>
                        <option value="">{t('Select')}</option>
                        <option value="angebot">{t('Angebot PDF')}</option>
                        <option value="auftrag">{t('Auftrag PDF')}</option>
                        <option value="fertigmeldung_attchments">{t('Fertigmeldung')}</option>
                        <option value="vollmacht">{t('Vollmacht')}</option>
                    </select>
                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                </div>

                {[...Array(totalEmails)].map((x, i) =>
                    <div className="form-group" key={i}>
                        <label>{t('Email')} *</label>
                        <div className="input-group mb-3">
                            <input type="email" onChange={(e) => setValue('email[' + i + ']', e.target.value)} defaultValue={getValues('email[' + i + ']')} className="form-control" placeholder={t('eg.') + ' email@mail.com'} />
                            <div className="input-group-append">
                                {totalEmails == i + 1 ? (
                                    <span onClick={() => { removeEmail() }} style={{ cursor: "pointer" }} className="input-group-text border-0">X</span>
                                ) : <></>}
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-group mb-3">
                    <a onClick={() => { addEmail() }}><i className="fa-solid fa-circle-plus"></i> Hinzufügen</a>
                </div>


                {submitSuccess ? (
                    <SoftAlert color={'success'} dismissible className="mt-2 dark-alert" style={{ fontWeight: '700', fontSize: '0.75rem' }}>
                        <SoftTypography variant="body2" color="white">
                            {t('E-Mail wurde erfolgreich versendet')}
                        </SoftTypography>
                    </SoftAlert>
                ) : ''}

                <div className="row g-3">
                    <div className="col-12">
                        <input type="submit" id="sendMail" className="d-none" />
                        {!saving && (
                            <button type="button" onClick={() => beforeSendMail()} className="btn btn-primary mb-0">
                                {t('Send')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled mb-0" disabled>
                                {t('Sending ...')}
                            </button>
                        )}
                    </div>
                </div>
            </form>



            <button type="button" className="btn btn-primary d-none" id="emailPopUp" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">

                        <div className="modal-body callcenter-area">

                            <div className="form-group">
                                <label>{t('Sender')}*</label>
                                <input type="text" readOnly value={'info@bayerische-landeswerke.de'} className="form-control" />
                            </div>

                            {[...Array(totalEmails)].map((x, i) =>
                                <div className="form-group" key={i}>
                                    <label>{t('Empfänger')} *</label>
                                    <div className="input-group mb-3">
                                        <input type="email" onChange={(e) => setValue('email[' + i + ']', e.target.value)} defaultValue={getValues('email[' + i + ']')} className="form-control" placeholder={t('eg.') + ' email@mail.com'} />
                                        <div className="input-group-append">
                                            {totalEmails == i + 1 ? (
                                                <span onClick={() => { removeEmail() }} style={{ cursor: "pointer" }} className="input-group-text border-0">X</span>
                                            ) : <></>}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="form-group mb-3">
                                <a onClick={() => { addEmail() }}><i className="fa-solid fa-circle-plus"></i> Hinzufügen</a>
                            </div>

                            <div className="form-group">
                                <label>{t('Betreff')}*</label>
                                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" />
                            </div>

                            <div className="form-group">
                                <label>{t('E-mail')}*</label>
                                <SoftEditor value={emailText} onChange={setEmailText} />
                            </div>

                        </div>
                        <div className="modal-footer">
                            {type == 'angebot' ? <div style={{ position: 'absolute', left: '13px' }}> <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + "quation/" + props.id}>  <svg style={{ height: '36px', width: '18px', position: 'relative', top: '-2px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#f53939" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg> <span style={{ color: '#005498' }} className="text-capitalize">{type}</span></a> </div> : ' '}
                            {type == 'auftrag' ? <div style={{ position: 'absolute', left: '13px' }}> <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + "order/" + props.id}>  <svg style={{ height: '36px', width: '18px', position: 'relative', top: '-2px' }} viewBox="0 0 56 54" xmlns="http://www.w3.org/2000/svg" width="2500" height="2411"><path d="M9.9 0h36.2C51.6 0 56 4.4 56 9.9v34.2c0 5.5-4.4 9.9-9.9 9.9H9.9C4.4 54 0 49.6 0 44.1V9.9C0 4.4 4.4 0 9.9 0z" fill="#f53939" /><path d="M45 31.2c-2.6-2.7-9.7-1.6-11.4-1.4-2.5-2.4-4.2-5.3-4.8-6.3.9-2.7 1.5-5.4 1.6-8.3 0-2.5-1-5.2-3.8-5.2-1 0-1.9.6-2.4 1.4-1.2 2.1-.7 6.3 1.2 10.6-1.1 3.1-2.1 6.1-4.9 11.4-2.9 1.2-9 4-9.5 7-.2.9.1 1.8.8 2.5.7.6 1.6.9 2.5.9 3.7 0 7.3-5.1 9.8-9.4 2.1-.7 5.4-1.7 8.7-2.3 3.9 3.4 7.3 3.9 9.1 3.9 2.4 0 3.3-1 3.6-1.9.5-1 .2-2.1-.5-2.9zm-2.5 1.7c-.1.7-1 1.4-2.6 1-1.9-.5-3.6-1.4-5.1-2.6 1.3-.2 4.2-.5 6.3-.1.8.2 1.6.7 1.4 1.7zM25.8 12.3c.2-.3.5-.5.8-.5.9 0 1.1 1.1 1.1 2-.1 2.1-.5 4.2-1.2 6.2-1.5-4-1.2-6.8-.7-7.7zm-.2 19.4c.8-1.6 1.9-4.4 2.3-5.6.9 1.5 2.4 3.3 3.2 4.1 0 .1-3.1.7-5.5 1.5zm-5.9 4c-2.3 3.8-4.7 6.2-6 6.2-.2 0-.4-.1-.6-.2-.3-.2-.4-.5-.3-.9.3-1.4 2.9-3.3 6.9-5.1z" fill="#fff" /></svg> <span style={{ color: '#005498' }} className="text-capitalize">{type}</span></a> </div> : ' '}


                            <button type="button" style={{ position: 'relative', top: '2px' }} className="btn btn-secondary mt-3" ref={modal} id="modalClose" data-bs-dismiss="modal">{t('Abbrechen')}</button>
                            {!saving && (
                                <button type="submit" onClick={() => { document.getElementById('sendMail').click(); setSaving(true) }} className="btn btn-primary mb-0">
                                    {t('Send')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled mb-0" disabled>
                                    {t('Sending ...')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {renderErrorSB}
            {renderErrorSBOrder}
        </>
    );
}

export default EmailForm;
