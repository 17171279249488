import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Reclamation from './Reclamation';
import Tickets from './Tickets';
import Cookies from 'js-cookie';
import Timeline from "../create/Timeline";
function Index(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    return (
        <>
            <div className="row">
                <div className="col-sm-9">
                    {JSON.parse(Cookies.get('permissions')).indexOf("RecR") !== -1 && props?.projectid ? (
                        <div className='row'>
                            <div className="col-12">
                                <Reclamation projectId={props.projectid} />
                            </div>
                        </div>
                    ) : <></>}

                    {JSON.parse(Cookies.get('permissions')).indexOf("TicR") !== -1 && props?.projectid ? (
                        <div className='row'>
                            <div className="col-12">
                                <Tickets projectId={props.projectid} />
                            </div>
                        </div>
                    ) : <></>}
                </div>
                <div className="col-sm-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {props.projectid ? props.projectid : '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Timeline refresh={refresh} acStatusEdit={true} />
                </div>
            </div>
        </>
    );
}

export default Index;
