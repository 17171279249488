import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch"; 
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; 

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox"; 
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData"; 
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const OrderFiles = (props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [dataa, setDataa] = useState([]);
    const [dachansicht, setDachansicht] = useState([]);
    const [attachments, setAttachment] = useState([]);
    const [ref, setRef] = useState(0);
    
    const removeAttachment = (id) => {
        // const beforeRemove = attachments;
        // const afterRemove = beforeRemove.filter(value => {
        //     return value.id != id;
        // });

        // setAttachment(afterRemove);

        // var formData = new FormData();
        // formData.attachments = JSON.stringify(attachments); 
        // formData.id = id; 
        // formData.project_id = props.project_id; 
        // callFetch("delete-order-files", "POST", formData ).then((res) => {
        //     setRef(ref+1);
        // });

    }

    useEffect(() => { 
        callFetch("get-order-uploaded-files/" + props.project_id, "GET", []).then((res) => { 
            setDataa(res.order);
        });
    }, [props.project_id, props.refresh, ref]); 
  return (
    <>
        <div className="row mb-5">
            <SoftBox p={3} className="order-processing">
                <Grid container spacing={3}>
                {dataa?
                    dataa?.map((item, index) => (  
                        <Grid item key={index}>
                            <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+item.attachment}>
                                <img src={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+item.attachment} />
                            </a>  
                        </Grid>  
                    )) : <></>} 
                </Grid>
            </SoftBox>
        </div>
        <ToastContainer />
    </>
  )
}

export default OrderFiles