import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";

import Select from 'react-select';
import SoftSnackbar from "components/SoftSnackbar";
import Timeline from "./Timeline";
import Cookies from 'js-cookie';

import ProjectStatus from '../ProjectStatus';
import Swal from 'sweetalert2';
import dateFormat from "dateformat";
import Services from "../Services/Index";
import Reclamation from "../Services/Reclamation";
import Tickets from "../Services/Tickets";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import TimelineItem from "../TimelineComponent/TimelineItem";
import TimelineList from "../TimelineComponent/TimelineList";
import profileImage from "assets/images/team-3.jpg";
// Images 

function ProjectPost(props) {
    const { t } = useTranslation();
    const submitBtn = useRef();
    const [data, setData] = useState([]);
    const [zipcode, setZipcode] = useState([]);
    const [selectedPlz, setSelectedPlz] = useState({ value: '', label: '---' });
    const [city, setCity] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [selecteCustomertId, setSelecteCustomertId] = useState({ id: '' });
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [formdata, setFormdata] = useState({});
    const [successSB, setSuccessSB] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [quation, setQuation] = useState([]);
    const [file, setFile] = useState();
    const [posts, setPosts] = useState([]);
    const [viewMode, setViewMode] = useState('overview');
    const chatConversationRef = useRef(null);
    const [total, setTotal] = useState('');
    const [remove, setRemove] = useState(false);
    const [errorSB, setErrorSB] = useState(false);

    const closeErrorSB = () => setErrorSB(false);

    const [userLead, setUserLead] = useState([]);
    // const handleAutoScroll = () => {
    //     if(chatConversationRef  != null){
    //         chatConversationRef.current.scrollTop = Number(chatConversationRef.current.scrollHeight) + Number(80); 
    //     } 
    // }

    //file upload
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0])); 
        setRemove(true);
    }

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );

    const renderErrorSB = (
        <SoftSnackbar
            color="error"
            icon="info"
            title={t('Nachricht, Notiz oder Info dürfen nicht leer sein')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={errorSB}
            onClose={() => closeErrorSB(false)}
            close={() => closeErrorSB(false)}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();






    //plz and ort load
    useEffect(() => {
        if (!data?.data?.id) return;

        setValue('customer_id', data?.data?.customer_id);
    }, [data]);

    useEffect(() => {
        if (!props?.customer) return;

        setValue('date', dateFormat(new Date(), "yyyy-mm-dd"));

        for (let [key, e] of Object.entries(customers)) {
            if (e.id == props?.customer) {
                setValue('customer_id', e.id);
                setSelecteCustomertId({
                    id: e?.id,
                    vorname: e?.vorname,
                    name: e?.name,
                    customer_number: e?.customer_number,
                });

                for (let [key, value] of Object.entries(e)) {
                    if (key == 'id') {

                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }

                setValue('full_address', e?.street + ' ' + e?.nr);
            }
        }

        //setValue('customer_id', data?.data?.customer_id);
    }, [props?.customer, customers]);


    //Edit Data load
    useEffect(() => {
        flatpickr(".flat-pickr");
        setValue('project_id', props.id);
        callFetch("get-project-posts/" + props.id, "GET", []).then((res) => {
            setPosts(res.project_posts);
        });
    }, [props.id, refresh]);

    //verkaufer id set
    useEffect(() => {
        setValue('verkaufer', data?.data?.verkaufer);
    }, [data]);

    //zipcode fetch
    useEffect(() => {
        for (let [zipcode_key, zipcode_value] of Object.entries(zipcode)) {
            if (zipcode_value.label == getValues('plz')) {
                setSelectedPlz(zipcode_value)
            }
        }
    }, [props.id, zipcode, refresh, getValues('plz')]);


    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true);
        if(formData.description_post == null || formData.description_post.length < 2){
            setErrorSB(true);
            setSaving(false);
            return false;
        }  
        
        callFetch("store-project-post", "POST", formData, setError).then((res) => {
            setValue('description_post', '');
            setValue('attachment', '');
            setPosts(res.project_posts);
            setRefresh(refresh + 1);
            setFile('');
            setRemove(false);
            setSaving(false);
            setSubmitSuccess(true);
        });
    };

    useEffect(() => {
        if (props?.status == 'new') {
            setSuccessSB(true);
        }
    }, [props?.status]);

    return <>
        <div className="card mt-3" style={{ padding: '5px' }}>
            <div className="card-body callcenter-area" style={{ padding: '10px', height: '420px', overflowY: 'auto' }} ref={chatConversationRef}>
                <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                    {posts && posts.map((post, key) => {
                        return <div className="timeline-block mb-3">
                            <span className="timeline-step">
                                <SoftAvatar src={post?.user?.photo ? process.env.REACT_APP_STORAGE_URL + post?.user?.photo : '/assets/img/placeholder.png'} alt={post?.user?.name} size="sm" variant="rounded" />
                            </span>
                            <div className="timeline-content" style={{ paddingTop: '0px' }}>
                                <h6 className="text-dark text-sm font-weight-bold mb-0">{post?.user?.name}</h6>
                                <p className="text-secondary font-weight-bold text-xs mt-1" style={{ marginBottom: '3px' }}>{dateFormat(post.created_at, "dd.mm.yyyy h:mm:ss")}</p>
                                <SoftTypography variant="caption" color="text" fontWeight="regular" style={{ display: 'block', lineHeight: '1.5', margin: '0px' }}>
                                    {post.description}
                                </SoftTypography>
                                {post.attachment && post.attachment !== null ? <img src={process.env.REACT_APP_BACKEND_URL + 'storage/project_post/' + post.attachment} alt="" style={{ height: '140px', borderRadius: '15px', objectFit: 'cover', width: '100%', marginTop: '5px' }} /> : ''}
                            </div>
                        </div>
                    })}
                </div>
                
                {posts.length ? (
                    <></>
                ) : <p className="text-center" style={{ lineHeight: '300px'}}>{t('Keine Nachrichten vorhanden')}</p>}                
            </div>
        </div>

        <div style={{ position: 'relative', bottom: '15px', width: '326px', background: '#fff', padding: '15px', paddingBottom: '5px', borderRadius: '10px' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SoftBox display="flex" justifyContent="" alignItems="flex-start" style={{ width: '100%' }}>
                    <SoftBox display="flex" style={{ width: '100%' }}>
                        <SoftBox display="flex" flexDirection="column" justifyContent="center" style={{ width: '100%' }}>
                            <textarea {...register('description_post')} className="form-control" placeholder={t("Ihre Nachricht, Notiz oder Info...")} style={{ width: '100%' }}></textarea>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <div className="row mt-2">
                    <div className="col-sm-4">

                    </div>
                    <div className="col-sm-3" style={{position: 'relative'}}> 
                        {remove ?  <><img src={file} style={{height: '38px', width: '100%', borderRadius: '5px'}} alt="" />  <span title="Remove Attachment" style={{ fontSize: '18px', right: '15px', top: '2px', position: 'absolute' }}><i style={{background: '#fff', padding: '3px', borderRadius: '8px' }} onClick={() => { setRemove(false); setFile(''); setValue('attachment', '')}} className="fa fa-remove" aria-hidden="true"></i></span></> : ''}
                       
                    </div> 
                    <div className="col-sm-2">
                        <div className="choose_file" style={{ marginRight: '10px' }}>
                            <span title="Attach File" style={{ fontSize: '22px' }}><i className="fa fa-paperclip" aria-hidden="true"></i></span> 
                            <input {...register('attachment')} id="fileUpload" type="file" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        {saving ? <button className="btn btn-secondary pull-right" disabled style={{ marginRight: '15px' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button> : <button className="btn btn-primary pull-right" style={{ marginRight: '15px' }}>
                            <i className="fa-solid fa-circle-arrow-right"></i>
                        </button> }
                        
                    </div>
                </div>
            </form>
        </div>
        <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
        {renderErrorSB}
    </>;
}

export default ProjectPost;
