/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBack from '@mui/icons-material/ArrowBack';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from 'react-i18next';
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

function Breadcrumbs({ icon, title, route, light }) {
  const { t } = useTranslation();
  const routes = route.slice(0, -1);
  let navigate = useNavigate();

  return (
    <>
      <SoftBox mr={2}>
        <SoftButton
          variant="outlined"
          color={'dark'}
          size="small"
          iconOnly
          circular
          onClick={()=> {
            //useHistory.goBack();
            Cookies.set('cacehQuery', 'yes');
            navigate(-1);
          }}
        >
          <ArrowBack />
        </SoftButton>
      </SoftBox>

      <SoftBox mr={{ xs: 0, xl: 8 }}>
        <MuiBreadcrumbs
          sx={{
            "& .MuiBreadcrumbs-separator": {
              color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
            },
          }}
        >

          <Link to="/">
            <SoftTypography
              component="span"
              variant="body2"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              <HomeIcon />
            </SoftTypography>
          </Link>
          {routes.map((el, index) => (
            <Link
              to={`/${index ?
                el == 'edit' && routes[index - 1] == 'projects' ? 'projects'
                  : routes[index - 1] + '/' + el


                : el == 'project-management' ? el + '/projects'
                  //: el == 'edit' ? '#'
                  : el
                }`
              }
              key={index ? routes[index - 1] + '/' + el : el}
            >
              <SoftTypography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? "white" : "dark"}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                {t(el.replace("-", " "))}
              </SoftTypography>
            </Link>
          ))}
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            sx={{ lineHeight: 0 }}
          >
            {t(title.replace("-", " "))}
          </SoftTypography>
        </MuiBreadcrumbs>
        <SoftTypography
          fontWeight="bold"
          textTransform="capitalize"
          variant="h6"
          color={light ? "white" : "dark"}
          noWrap
        >
          {t(title.replace("-", " "))}
        </SoftTypography>
      </SoftBox>
    </>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
