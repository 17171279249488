import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Select from 'react-select';
import SoftSnackbar from "components/SoftSnackbar";
import TimelineNew from "./TimelineNew";
import Cookies from 'js-cookie';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { NumericFormat } from 'react-number-format';
import ProjectStatus from '../ProjectStatus';
import Swal from 'sweetalert2';
import dateFormat from "dateformat";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SoftButton from "components/SoftButton";
import SignaturePad from 'react-signature-pad-wrapper'
// Images
import mercedesEQC from "assets/images/Mono-S4_HC_WhiteBacksheet_diagonal_hoch_live 2.png";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import iconbtn from "assets/images/iconbtn.png";
import logo from "assets/images/logo.png";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import linearGradient from "assets/theme/functions/linearGradient";

function CallCenter(props) {
    let params = useParams();
    const { t } = useTranslation();
    const submitBtn = useRef();
    const [data, setData] = useState([]);
    const [zipcode, setZipcode] = useState([]);
    const [selectedPlz, setSelectedPlz] = useState({ value: '', label: '---' });
    const [city, setCity] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [selecteCustomertId, setSelecteCustomertId] = useState({ id: '' });
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [formdata, setFormdata] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [successSB, setSuccessSB] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [quation, setQuation] = useState([]);
    const [sending, setSending] = useState(false);
    const [openPDF, setOpenPDF] = useState(false);
    const signaturePadRefClient = useRef(null);
    const [showPadClient, setShowPadClient] = useState(true);
    const [showPad, setShowPad] = useState(true);
    const [playAnimation, setPlayAnimation] = useState(true);
    const [signNew, setSignNew] = useState(false);
    const [id, setID] = useState(0);

    const openThePDF = () => {
        setOpenPDF(true);
        var url = "get-client-sign-order-overview/" + params?.id
        callFetch(url, "GET", []).then((res) => {
            if (res.quation?.client_sign_order_overview) {
                signaturePadRefClient.current.fromDataURL(res.quation?.client_sign_order_overview);
            }

        })
    }

    useEffect(() => { // useEffect hook
        if (playAnimation) {
            //document.body.classList.add('loading');
        }
        setTimeout(() => { // simulate a delay
            setPlayAnimation(false)
            //document.body.classList.remove('loading');
        }, 10000);
    }, [refresh]);
    const hideSignClient = () => {
        setShowPadClient(true);
    }

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();


    const onSubmit = (formData) => {
        if (signaturePadRefClient.current.isEmpty()) {
            // alert('Sign please');
            setRefresh(refresh + 1);
            return false;
        }

        setSaving(true);

        formData.client_sign = signaturePadRefClient.current.toDataURL('image/png');
        formData.id = data?.data?.quations?.id;

        var url = "save-client-sign-order-overview";
        callFetch(url, "POST", formData, setError).then((res) => {
            setRefresh(refresh + 1);
            setTimeout(() => { setID(data?.data?.quations?.id); setSignNew(true) }, 5000);
            setTimeout(() => { setSignNew(false) }, 10000);
            setPlayAnimation(true);
            setSaving(false);
        });
    }

    //Edit Data load
    useEffect(() => {
        flatpickr(".flat-pickr");

        if (Cookies.get('permissions').indexOf("project_call_center-update") !== -1) {
            setReadOnly(false);
        } else {
            setReadOnly(true);
        }


        if (params?.id) {
            setCreate(false);
            //callFetch("projects/" + params.id + "/edit?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
            callFetch("order-overview/" + params.id, "GET", []).then((res) => {
                setData(res);
                console.log(res);
                setQuation(res.quation);
                setInvoices(res.invoices);
                for (let [key, value] of Object.entries(res.data)) {
                    if (key == 'date') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'plz') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'customer') {
                        setSelecteCustomertId(value)
                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }
                setValue('full_address', res?.data?.street + ' ' + res?.data?.nr);
            });
        }
    }, [params?.id, refesh3]);


    return submitSuccess && create ? <Navigate to={'/projects/' + projectId} /> :
        <>
            <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3  bg-white" id="sidenav-main" style={{ boxShadow: 'none' }}>
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                    <a className="navbar-brand m-0" href="/">
                        <img src="/assets/img/logonew.png" className="navbar-brand-img h-100" style={{ maxHeight: '50px', marginTop: '16px' }} alt="main_logo" />
                    </a>
                </div>
                <hr className="horizontal dark mt-0" />
                <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to="/dashboard" className="nav-link active" style={{ background: '#CB0C9FA6', color: '#fff' }}>
                                <i aria-hidden="true" style={{ background: '#fff', color: '#CB0C9FA6', padding: '8px' }} className="border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_4276_16989)">
                                        <path d="M7.66289 2.96736C7.61927 2.92563 7.56124 2.90234 7.50088 2.90234C7.44052 2.90234 7.38248 2.92563 7.33887 2.96736L1.94531 8.11979C1.92241 8.14171 1.90418 8.16804 1.89175 8.19719C1.87931 8.22635 1.87291 8.25773 1.87295 8.28942L1.87207 13.1252C1.87207 13.3738 1.97084 13.6123 2.14666 13.7881C2.32247 13.9639 2.56093 14.0627 2.80957 14.0627H5.625C5.74932 14.0627 5.86855 14.0133 5.95645 13.9254C6.04436 13.8375 6.09375 13.7182 6.09375 13.5939V9.60954C6.09375 9.54738 6.11844 9.48777 6.1624 9.44381C6.20635 9.39986 6.26596 9.37517 6.32812 9.37517H8.67187C8.73403 9.37517 8.79365 9.39986 8.8376 9.44381C8.88155 9.48777 8.90625 9.54738 8.90625 9.60954V13.5939C8.90625 13.7182 8.95563 13.8375 9.04354 13.9254C9.13145 14.0133 9.25068 14.0627 9.375 14.0627H12.1893C12.4379 14.0627 12.6764 13.9639 12.8522 13.7881C13.028 13.6123 13.1268 13.3738 13.1268 13.1252V8.28942C13.1268 8.25773 13.1204 8.22635 13.108 8.19719C13.0955 8.16804 13.0773 8.14171 13.0544 8.11979L7.66289 2.96736Z" fill="#CB0C9F" fill-opacity="0.65" />
                                        <path d="M14.3821 7.15283L12.1907 5.05635V1.875C12.1907 1.75068 12.1413 1.63145 12.0534 1.54354C11.9655 1.45564 11.8463 1.40625 11.722 1.40625H10.3157C10.1914 1.40625 10.0722 1.45564 9.98427 1.54354C9.89637 1.63145 9.84698 1.75068 9.84698 1.875V2.8125L8.15011 1.19004C7.99132 1.02949 7.75518 0.9375 7.50001 0.9375C7.24571 0.9375 7.01016 1.02949 6.85138 1.19033L0.619933 7.15225C0.437706 7.32803 0.414855 7.61719 0.580675 7.80762C0.622315 7.85568 0.6733 7.89477 0.730528 7.92251C0.787756 7.95025 0.850029 7.96605 0.913558 7.96896C0.977087 7.97186 1.04054 7.96181 1.10006 7.93941C1.15958 7.91701 1.21392 7.88274 1.25978 7.83867L7.33888 2.02969C7.38249 1.98796 7.44053 1.96468 7.50089 1.96468C7.56125 1.96468 7.61928 1.98796 7.6629 2.02969L13.7426 7.83867C13.8321 7.92455 13.9521 7.97142 14.0762 7.96901C14.2002 7.96659 14.3183 7.91509 14.4044 7.82578C14.5843 7.63945 14.5693 7.33183 14.3821 7.15283Z" fill="#CB0C9F" fill-opacity="0.65" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4276_16989">
                                            <rect width="15" height="15" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                </i>
                                <span className="sidenav-normal">{t('Ihre Bestellübersicht')}</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>
            <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg" id="main-content" style={{ backgroundColor: '#fff' }}>
                <div className="container-fluid py-4 min-height-500">
                    <div className="row mb-5">
                        <div className="col-lg-12 mt-lg-0">

                            {params.id > 0 && (
                                <>
                                    <br />
                                    <br />
                                    <br />
                                    <h4 className="mt-5">Ihre Bestellübersicht</h4>
                                    <div className="py-3 px-5" style={{ background: 'linear-gradient(263.4deg, #130744 14.32%, #208DFC 77.34%)', borderRadius: '1rem' }}>
                                        <SoftBox
                                            component="img"
                                            src={wavesWhite}
                                            alt="pattern-line"
                                            width="100%"
                                            position="absolute"
                                            left="0"
                                            top="0"
                                            opacity={0.4}
                                        />
                                        <Grid container alignItems="center" position="relative">
                                            <Grid item xs={12} lg={4}>
                                                <SoftBox px={{ xs: 0, md: 1.5 }}>
                                                    <SoftTypography variant="h4" color="white" textTransform="capitalize" opacity={0.9}>
                                                        {data?.data?.quations?.module_eingeben == 'yes' ? data?.data?.quations?.module_name : data?.data?.quations?.module?.name} |
                                                        {data?.data?.quations?.module_eingeben == 'yes' ?
                                                            data?.data?.quations?.module_description ? JSON.parse(data?.data?.quations?.module_description).Markenhersteller : ''
                                                            : (data?.data?.quations?.module?.description ? JSON.parse(data?.data?.quations?.module?.description).Markenhersteller : '')}
                                                    </SoftTypography>
                                                    <Divider light />
                                                    <SoftBox display="flex">
                                                        <SoftBox>
                                                            <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                                                                power
                                                            </SoftTypography>
                                                            <SoftTypography variant="h3" fontWeight="bold" color="white">
                                                                {data?.data?.quations?.module_eingeben == 'yes' ? data?.data?.quations?.module_watt : data?.data?.quations?.module?.watt}{" "}
                                                                <SoftTypography variant="button" color="white" verticalAlign="top">W</SoftTypography>
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={{ xs: 3, md: 8 }} mb={{ xs: 1, md: 0 }}>
                                                            <SoftTypography variant="h6" color="white" textTransform="capitalize" opacity={0.7}>
                                                                Abmessung
                                                            </SoftTypography>
                                                            <SoftTypography variant="h3" fontWeight="bold" color="white">
                                                                {data?.data?.quations?.module_eingeben == 'yes' ?
                                                                    data?.data?.quations?.module_description ? JSON.parse(data?.data?.quations?.module_description).Modulabmessung_width : ''
                                                                    : (data?.data?.quations?.module?.description ? JSON.parse(data?.data?.quations?.module?.description).Modulabmessung_width : '')}{" "} -

                                                                {data?.data?.quations?.module_eingeben == 'yes' ?
                                                                    data?.data?.quations?.module_description ? JSON.parse(data?.data?.quations?.module_description).Modulabmessung_height : ''
                                                                    : (data?.data?.quations?.module?.description ? JSON.parse(data?.data?.quations?.module?.description).Modulabmessung_height : '')}{" "}
                                                                <SoftTypography variant="button" color="white" verticalAlign="top">mm</SoftTypography>
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} lg={5}>
                                                <SoftBox textAlign="center">
                                                    <SoftBox
                                                        component="img"
                                                        src={mercedesEQC}
                                                        alt="car image"
                                                        width="auto"
                                                        justifyContent={{ xs: "flex-center", md: "center" }}
                                                        mt={{ xs: 0, lg: -30 }}
                                                    />
                                                    <SoftBox
                                                        display="flex"
                                                        justifyContent={{ xs: "flex-center", md: "center" }}
                                                        alignItems="center"
                                                        mb={1}
                                                    >
                                                        <SoftTypography variant="h4" color="white" textTransform="capitalize" opacity={0.7}>
                                                            Wirkungsgrad
                                                        </SoftTypography>
                                                        <SoftBox ml={1}>
                                                            <SoftTypography variant="h2" fontWeight="bold" color="white">
                                                                21
                                                                <SoftTypography
                                                                    variant="button"
                                                                    fontWeight="bold"
                                                                    color="white"
                                                                    verticalAlign="top"
                                                                >
                                                                    %
                                                                </SoftTypography>
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} lg={3}>
                                                <SoftBox px={{ xs: 0, md: 1.5 }}>
                                                    <SoftTypography variant="h5" color="white" textTransform="capitalize" opacity={0.9}>
                                                        {data?.data?.quations?.module_eingeben == 'yes' ? data?.data?.quations?.module_short_title : data?.data?.quations?.module?.short_itle}
                                                    </SoftTypography>
                                                    <SoftBox display="flex">
                                                        <SoftBox>
                                                            <SoftTypography variant="caption" color="white">
                                                                Leistungsgarantie 30 Jahre
                                                            </SoftTypography>
                                                            <br />
                                                            <SoftTypography variant="caption" color="white">
                                                                Produktgarantie 30 Jahre
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={8} mt={2}>

                                                            <SoftButton variant="" circular iconOnly>
                                                                <img src={iconbtn} alt="" />
                                                            </SoftButton>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="row mt-4 gx-8">
                                        {openPDF ? <><div className="col-sm-6">
                                            <a onClick={() => setOpenPDF(false)} style={{ display: 'inline-block', position: 'relative', top: '-12px', borderBottom: '1px solid rgb(0, 84, 152)', fontSize: '15px', color: 'rgb(0, 84, 152)', margin: '0px', padding: '0px', fontWeight: '700' }}><i className="fa-solid fa-arrow-left-long"></i> {t('Zurück')}</a>
                                            {signNew ? <iframe src={process.env.REACT_APP_BACKEND_URL + 'quation/' + id + '#page=5'} id="" style={{ width: '100%', height: '1123px' }}></iframe> : <iframe src={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.data?.quations?.id + '#page=5'} id="" style={{ width: '100%', height: '1123px' }}></iframe>}

                                        </div>
                                            <div className="col-sm-6" style={{ paddingLeft: '0px' }}>
                                                <div className="row">
                                                    <div className="col-lg-12 mt-lg-0" style={{ position: 'relative' }}>
                                                        {/* {playAnimation ? <div className="loader-pdf"><p>Loading...</p></div> : ''} */}
                                                        <div className={playAnimation ? 'mb-4 loading' : "mb-4"}>

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    {!showPadClient ? <div><div className="sign-pad">
                                                                        <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{ position: 'relative', top: '140px' }}></i>
                                                                        <img style={{ width: '100%' }} src={data && data.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data.order.client_sign : ''} alt="" />
                                                                        <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>{t('Unterschrift Auftraggeber')}</p>
                                                                    </div> <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                                            <i className="fa fa-eraser"></i> Radieren
                                                                        </button></div> : ' '}
                                                                    {showPadClient ? <div><div className="sign-pad-new">
                                                                        <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)' }} ref={signaturePadRefClient} />
                                                                        <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>{t('Unterschrift Auftraggeber')}</p>
                                                                    </div>
                                                                    </div> : ''}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={"row " + (props?.saveBtnDisplay == false ? 'd-none' : '')}>
                                                            <div className="col-sm-7"></div>
                                                            <div className="col-sm-3 text-right" style={{ textAlign: 'right' }}>
                                                                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                                                    <i className="fa fa-eraser"></i> Radieren
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                {!saving && (
                                                                    <button type="button" id="saveSign" onClick={() => onSubmit({})} className="btn btn-primary" >
                                                                        {t('Save')}
                                                                    </button>

                                                                )}
                                                                {saving && (
                                                                    <button type="button" className="btn btn-disabled" disabled>
                                                                        {t('Saving ...')}
                                                                    </button>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div></> : <><div className="col">
                                                <TimelineNew refresh={refresh2} />
                                                <div className="mt-3" style={{ padding: '15px', position: 'relative', color: '#fff', borderRadius: '15px', background: 'linear-gradient(360deg, #0962CA 0%, #CB0C9F 100%)', height: '150px', width: '100%' }}>
                                                    <SoftTypography color="#fff" variant="h4">750 € Prämie sichern</SoftTypography>
                                                    <SoftTypography color="#fff" variant="caption" fontSize="17px">Empfehlen Sie Ihren Kunden BLW Landeswerke GmbH weiter und erhalten Sie eine Sonderprämie i.h.v 750 EURO </SoftTypography>
                                                    <a className="text-white" style={{ position: 'absolute', bottom: '15px', right: '28px', fontSize: '12px' }}>Erfahre mehr <i className="fa fa-arrow-right"></i> </a>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <SoftTypography variant="h6" fontWeight="bold">Lieferadresse <span style={{ color: 'red', fontSize: '10px' }}>(Kundendaten löschen)</span></SoftTypography>
                                                <div className="card mt-2 mb-3" style={{ backgroundColor: '#F8F9FA', boxShadow: 'none' }}>
                                                    <div className="card-body" style={{ padding: '15px' }}>
                                                        <SoftBox>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Name:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontSizeXS fontWeight="bold"> {data?.data?.vorname}  {data?.data?.name}</SoftTypography> </div>
                                                            </div>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Phone:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold"> {data?.data?.telefonnummer} </SoftTypography> </div>
                                                            </div>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>E-mail:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold"> {data?.data?.email} </SoftTypography> </div>
                                                            </div>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Address:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold">{data?.data?.street}, {data?.data?.plz}, {data?.data?.ort} </SoftTypography> </div>
                                                            </div>
                                                        </SoftBox>
                                                    </div>
                                                </div>

                                                <SoftTypography variant="h6" fontWeight="bold">Auftrag Details</SoftTypography>
                                                <div className="card mt-2 mb-3" style={{ backgroundColor: '#F8F9FA', boxShadow: 'none' }}>
                                                    <div className="card-body" style={{ padding: '15px', height: '130px' }}>
                                                        <SoftBox>
                                                            <table style={{ width: '100%' }}>
                                                                {data?.data?.quations?.id && (
                                                                    <tr>
                                                                        <td style={{ width: '25%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Angebot:</SoftTypography></td>
                                                                        <td style={{ width: '50%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>{data?.data?.quations?.id} vom {dateFormat(data?.data?.quations?.created_at, "dd.mm.yyyy")}</SoftTypography></td>
                                                                        <td style={{ width: '25%', textAlign: 'center', color: '#5E25FF' }}> <a target={"_blank"} rel="noreferrer" style={{ color: '#5E25FF' }} href={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.data?.quations?.id}> <SoftTypography variant="caption" color="#5E25FF" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}><i className="fa fa-file-lines"></i> PDF</SoftTypography> </a></td>
                                                                    </tr>
                                                                )}
                                                                {data?.data?.quations?.order?.id && (
                                                                    <tr>
                                                                        <td style={{ width: '25%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Auftrag:</SoftTypography></td>
                                                                        <td style={{ width: '50%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>{data?.data?.quations?.order?.id} vom {dateFormat(data?.data?.quations?.order?.created_at, "dd.mm.yyyy")}</SoftTypography></td>
                                                                        <td style={{ width: '25%', textAlign: 'center', color: '#5E25FF' }}>   <a target={"_blank"} rel="noreferrer" style={{ color: '#5E25FF' }} href={process.env.REACT_APP_BACKEND_URL + 'order/' + data?.data?.quations?.order?.id}> <SoftTypography variant="caption" color="#5E25FF" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}><i className="fa fa-file-lines"></i> PDF</SoftTypography> </a></td>
                                                                    </tr>
                                                                )}

                                                                {data?.data?.quations?.order?.invoices && data?.data?.quations?.order?.invoices.map((invoice, key) => (
                                                                    <tr>
                                                                        <td style={{ width: '25%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>{key + 1}. Rechnung:</SoftTypography></td>
                                                                        <td style={{ width: '50%' }}><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}>{invoice.id} vom {dateFormat(invoice.created_at, "dd.mm.yyyy")}</SoftTypography></td>
                                                                        <td style={{ width: '25%', textAlign: 'center', color: '#5E25FF' }}> <a target={"_blank"} rel="noreferrer" style={{ color: '#5E25FF' }} href={process.env.REACT_APP_BACKEND_URL + 'invoice/' + invoice.id}> <SoftTypography variant="caption" color="#5E25FF" fontSize="12px" fontSizeXS style={{ fontWeight: '700' }}><i className="fa fa-file-lines"></i> PDF</SoftTypography> </a></td>
                                                                    </tr>
                                                                ))}
                                                            </table>
                                                        </SoftBox>
                                                    </div>
                                                </div>

                                                <SoftTypography variant="h6" fontWeight="bold">Rechnung Details</SoftTypography>
                                                <div className="card mt-2" style={{ backgroundColor: '#F8F9FA', boxShadow: 'none' }}>
                                                    <div className="card-body" style={{ padding: '15px' }}>
                                                        <SoftBox>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" fontSizeXS style={{ fontWeight: '400' }}>Rechnungsempfänger:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontSizeXS fontWeight="bold"> {data?.data?.name}  {data?.data?.vorename}</SoftTypography> </div>
                                                            </div>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Mobil:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold"> {data?.data?.telefonnummer}</SoftTypography> </div>
                                                            </div>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>Straße, Nr.:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold">{data?.data?.email}</SoftTypography> </div>
                                                            </div>
                                                            <div className="row" style={{ lineHeight: '16px' }}>
                                                                <div className="col-sm-12"><SoftTypography variant="caption" fontSize="12px" style={{ fontWeight: '400' }}>PLZ, Ort:</SoftTypography> <SoftTypography variant="caption" fontSize="12px" fontWeight="bold">{data?.data?.street}, {data?.data?.plz}, {data?.data?.ort}</SoftTypography> </div>
                                                            </div>
                                                        </SoftBox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <SoftTypography variant="h6" fontWeight="bold">Ihr Lieferant</SoftTypography>
                                                <img src={logo} alt="" className="mt-2" style={{ height: '60px' }} />
                                                <div className="row">
                                                    <div className="col-sm-7 pt-4">
                                                        <SoftTypography variant="h6" fontWeight="bold">BLW Landeswerke GmbH</SoftTypography>
                                                        <SoftTypography variant="caption" style={{ display: 'inline-block', lineHeight: '16px' }}>
                                                            Landshuter Str. 22 <br />
                                                            85716 Unterschleißheim <br />
                                                            info@bayerische-landeswerke.de <br />
                                                            T 08131/ 33 281 11  <br />
                                                        </SoftTypography>
                                                    </div>
                                                    <div className="col-sm-5 pt-4 " style={{ textAlign: 'right' }}>
                                                        <button className="btn btn-primary" style={{ background: ('linear-gradient(121.78deg, #0048B1 9.06%, #2152FF 88.15%') }}>Kontaktieren</button>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-sm-12 pt-3">
                                                        <SoftTypography variant="h6" fontWeight="bold">Zahlungsmodalitäten</SoftTypography>

                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-sm-9" style={{ lineHeight: '2.5' }}>
                                                        <SoftTypography variant="caption" fontWeight="regular">Summe: </SoftTypography>
                                                    </div>
                                                    <div className="col-sm-3" style={{ textAlign: 'right' }}>
                                                        <SoftTypography variant="caption" fontWeight="bold"><NumericFormat value={data?.data?.quations?.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> € </SoftTypography>
                                                    </div>
                                                </div>
                                                {invoices && invoices.map((invoice, key) => (
                                                    <div className="row">
                                                        <div className="col-sm-9" style={{ lineHeight: '2.5' }}>
                                                            <SoftTypography variant="caption" fontWeight="regular">{key + 1}.Abschlag {invoice.amountPerchantage}% </SoftTypography> &nbsp;&nbsp;&nbsp;&nbsp; {invoice.status == 0 ? <span className="badge badge-danger">Zahlung offen</span> : <span className="badge badge-success">Zahlung erhalten</span>}
                                                        </div>
                                                        <div className="col-sm-3" style={{ textAlign: 'right' }}>
                                                            <SoftTypography variant="caption" fontWeight="bold"><NumericFormat value={invoice.brutto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</SoftTypography>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="row">
                                                    <div className="col-sm-9" style={{ lineHeight: '2.5' }}>
                                                        <SoftTypography variant="caption" fontWeight="regular">Gesamtsumme Netto: </SoftTypography>
                                                    </div>
                                                    <div className="col-sm-3" style={{ textAlign: 'right' }}>
                                                        <SoftTypography variant="caption" fontWeight="bold"><NumericFormat value={data?.data?.quations?.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} /> €</SoftTypography>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-sm-12" style={{ textAlign: 'right' }}>
                                                        <button onClick={() => openThePDF(true)} className="btn btn-primary" style={{ backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}>Angebot Unterschreiben</button>
                                                    </div>
                                                </div>
                                            </div></>}
                                    </div>
                                </>
                            )}
                        </div>
                        {renderSuccessSB}
                    </div>

                    <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
                </div>
            </main>
        </>;
}

export default CallCenter;