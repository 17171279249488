import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "../../helpers/callFetch";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dateFormat, { masks } from "dateformat";
import Timeline from "./Timeline";
import CheckListManageModal from "./CheckList"
import Edit from "./Edit";
import Cookies from "js-cookie";
import SoftSnackbar from "components/SoftSnackbar";

function Show(props) {
  let params = useParams();
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [userrec, setUserRec] = useState([]);
  const [checkLists, setCheckLists] = useState([]);
  const [saving, setSaving] = useState(false);
  const [seen, setSeen] = useState(0);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('success');

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const renderdisplayMessage = (
    <SoftSnackbar
      color={messageColor}
      icon=""
      title={t(message)}
      //content={t("Changes saved successfully")}
      dateTime={t("")}
      open={displayMessage}
      onClose={() => setDisplayMessage(false)}
      close={() => setDisplayMessage(false)}
      bgSuccess
    />
  );

  const hideReclamationModal = () => {
    var manageCheckListModal = document.getElementById("createCheckListModalOpen");
    manageCheckListModal.click();
  };

  const changeSeenStatus = () => {
    callFetch("update-reclamation-seen/" + (props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID), "GET", []).then((res) => {
      setSeen(res.seen);
    });
  };

  useEffect(() => {

    //if (!(props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID)) return;
    if (props?.viewRelamation?.id > 0 || params.rID > 0) {

    } else {
      return;
    }

    callFetch("get-reclamation-to-show/" + (props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID), "GET", []).then(
      (res) => {
        setData(res.data);
        setSeen(res.data?.seen);
        setUserRec(res.user);
        setCheckLists(res.checkLists);
        if (res?.data?.attachments) {
          setAttachment(JSON.parse(res?.data?.attachments));
        }

        setItems(JSON.parse(res?.data?.products));
        //setRefresh(refresh + 1);
      }
    );
  }, [props?.viewRelamation?.id, params?.rid, refresh]);

  const statusUpdate = () => {
    var formData = {}
    formData.status = (data?.status ? 0 : 1)

    setSaving(true);
    callFetch("reclamation/update/" + (props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID), "POST", formData, setError).then((res) => {
      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    });
  }

  const acFreigeben = () => {
    var formData = {}
    formData.ac_freigeben = (data?.ac_freigeben ? 0 : 1)

    setSaving(true);
    callFetch("reclamation/update/" + (props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID), "POST", formData, setError).then((res) => {
      if (res.message != 'success') {
        setMessageColor('error')
        setMessage('Bitte korrigieren Sie die Kundenadresse')
        setDisplayMessage(true);
      } else {
        setMessageColor('success')
        setMessage('Freigabe erfolgreich')
        setDisplayMessage(true);
      }

      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    });
  }

  const dcFreigeben = () => {
    var formData = {}
    formData.dc_freigeben = (data?.dc_freigeben ? 0 : 1)

    setSaving(true);
    callFetch("reclamation/update/" + (props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID), "POST", formData, setError).then((res) => {

      if (res.message != 'success') {
        setMessageColor('error')
        setMessage('Bitte korrigieren Sie die Kundenadresse')
        setDisplayMessage(true);
      } else {
        setMessageColor('success')
        setMessage('Freigabe erfolgreich')
        setDisplayMessage(true);
      }

      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    });
  }

  return (
    <>
      <Grid container >
        <Grid item xs={12} lg={props?.fullwidth ? 12 : 9}>
          <Card>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={3} lg={3}>
                {" "}
              </Grid>
              <Grid item xs={12} lg={12}>
                <SoftBox px={2}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox>
                      <SoftBox mb={1}>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {data && data?.title}
                        </SoftTypography>
                      </SoftBox>

                      <p
                        style={{
                          fontSize: '0.75rem',
                          color: 'rgb(52, 71, 103)',
                        }}
                      >
                        Priorität ist{" "}
                        {data?.priority == 'Urgend' ?
                          <span style={{ color: "#E53E3E" }}>{t(data?.priority)}</span>
                          : data?.priority == 'Normal' ?
                            <span style={{ color: "#CB0C9F" }}>{t(data?.priority)}</span>
                            : data?.priority == 'Not Important' ?
                              <span style={{ color: "#72AC27" }}>{t(data?.priority)}</span>
                              : ''}
                        {" "}
                        - Reklamation erstellt von{" "}
                        <span style={{ color: "#0048B1" }}>
                          {userrec && userrec.name}
                        </span>
                      </p>

                    </SoftBox>
                    {JSON.parse(Cookies.get('permissions')).indexOf("RecU") !== -1 ? (
                      <div>
                        {seen == 0 ? <a href="#" class="btn btn-outline-secondary" onClick={() => changeSeenStatus()} style={{ marginRight: '5px' }}>
                          <i className="fa fa-eye"></i>
                        </a> : <a href="#" class="btn btn-outline-secondary" onClick={() => changeSeenStatus()} style={{ marginRight: '5px' }}>
                          <i className="fa fa-eye-slash"></i>
                        </a>}
                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" class="btn btn-outline-secondary px-5">
                          Bearbeiten
                        </a>
                      </div>
                    ) : <></>}
                  </SoftBox>
                  <div
                    style={{
                      backgroundColor: "#eee",
                      width: "100%",
                      height: "1px",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  ></div>
                </SoftBox>

                <Grid container justifyContent="center">
                  <Grid item xs={12} lg={8}>
                    {JSON.parse(Cookies.get('permissions')).indexOf("RecU") !== -1 ? (
                      <div className="mt-0">
                        {data?.status == 1 ? (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                              statusUpdate();
                            }}
                            style={{
                              marginLeft: "15px",
                              backgroundColor: "#48BB78",
                              boxShadow: "none",
                            }}
                          >
                            <i className="fa fa-check"></i> &nbsp;&nbsp; Erledigt
                          </button>

                        ) : (<>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              statusUpdate();
                            }}
                            style={{
                              marginLeft: "15px",
                              boxShadow: "none",
                            }}
                          >
                            <i className="fa fa-check"></i> &nbsp;&nbsp; {t('Open')}
                          </button>
                        </>)}

                        {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacRec") !== -1 ? (
                          <button
                            type="button"
                            className="btn btn-danger ms-3"
                            onClick={() => {
                              dcFreigeben();
                            }}
                          >{data?.dc_freigeben == 1 ? t('DC Approved') : t('DC Approve')}</button>
                        ) : <></>}
                        {JSON.parse(Cookies.get('permissions')).indexOf("OnlyElkRec") !== -1 ? (
                          <button
                            type="button"
                            className="btn btn-success ms-3"
                            onClick={() => {
                              acFreigeben();
                            }}
                          >{data?.ac_freigeben == 1 ? t('AC Approved') : t('AC Approve')}</button>
                        ) : <></>}

                        {JSON.parse(Cookies.get('permissions')).indexOf("OnlyDacRec") !== -1 || JSON.parse(Cookies.get('permissions')).indexOf("OnlyElkRec") !== -1 ? (
                          <></>
                        ) : <>
                          <button
                            type="button"
                            className="btn btn-danger ms-3"
                            onClick={() => {
                              dcFreigeben();
                            }}
                          >{data?.dc_freigeben == 1 ? t('DC Approved') : t('DC Approve')}</button>

                          <button
                            type="button"
                            className="btn btn-success ms-3"
                            onClick={() => {
                              acFreigeben();
                            }}
                          >{data?.ac_freigeben == 1 ? t('AC Approved') : t('AC Approve')}</button>

                        </>}



                      </div>
                    ) : <></>}


                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      <SoftTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Beschreibung
                      </SoftTypography>{" "}

                      <br />
                      <SoftTypography
                        variant="p"
                        className="text-justify"
                        color="text"
                        style={{ textAlign: "justify" }}
                      >
                        {data && data?.description}
                      </SoftTypography>
                    </div>
                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      {JSON.parse(Cookies.get('permissions')).indexOf("RecU") !== -1 ? (
                        <a
                          href="#"
                          onClick={hideReclamationModal}
                          id="checkListModalOpen"
                          style={{ fontSize: "12px" }}

                        >
                          <i
                            class="fa-solid fa-plus plus-icon"
                            style={{
                              backgroundColor: "#0048B1",
                              color: "#fff",
                              borderRadius: "100%",
                              fontWeight: "900",
                              width: "15px",
                              height: "15px",
                              textAlign: "center",
                              paddingTop: "2px",
                            }}
                          ></i>{" "}
                          <span style={{ color: "#0048B1", fontWeight: "600" }}>
                            Checklisten Eintrag
                          </span>{" "}
                        </a>
                      ) : <></>}


                      <div className="row mt-2">
                        <div className="col-sm-12 mt-2">
                          {checkLists && checkLists.map((checklist) => (
                            <div class="form-check">
                              <input class="form-check-input" {...register("is_product_related_complain")} type="checkbox" id={"customCheck-" + checklist.id} />
                              <SoftTypography
                                variant="p"
                                className="text-justify"
                                color="text"
                                style={{
                                  textAlign: "justify",
                                  fontSize: '0.75rem',
                                  color: 'rgb(52, 71, 103)',
                                }}
                              >
                                {checklist.title}
                              </SoftTypography>
                            </div>
                          ))}
                        </div>
                      </div>

                    </div>

                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      <a
                        href="#"
                        style={{ fontSize: "12px" }}

                      >
                        <span style={{ color: "#0048B1", fontWeight: "600" }}>
                          {t('Products')}
                        </span>{" "}
                      </a>

                      <ul
                        className="m-0 p-0 list-unstyled"
                        style={{
                          fontSize: '0.75rem',
                          color: 'rgb(52, 71, 103)',
                        }}
                      >
                        {items.map(function (data, i) {
                          return (
                            <li>{data?.quantity} Stück &nbsp; &nbsp; &nbsp; {data?.title}</li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      <SoftBox className="order-processing">
                        <a
                          href="#"
                          style={{ fontSize: "12px" }}

                        >
                          <span style={{ color: "#0048B1", fontWeight: "600" }}>
                            {t('Attachements')}
                          </span>{" "}
                        </a>
                        <Grid container spacing={3}>
                          {attachments.map(function (data, i) {
                            return (
                              <Grid item key={i} className="text-center">
                                <div>
                                  <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment}>
                                    {['jpeg', 'jpg', 'png'].indexOf(data?.extension) !== -1 ? (
                                      <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment} />
                                    ) : (
                                      <div className="placeholder">{data?.extension}</div>
                                    )}
                                  </a>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </SoftBox>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ paddingRight: "15px" }}>
                    <SoftBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mt={2}
                      mb={3}
                    >
                      <SoftBox
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        lineHeight={1}
                      >
                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            Reklamation: R-{data && data?.id} &nbsp; <span style={{ fontWeight: '300', fontStyle: 'italic' }}>{data?.ticket?.assignorderprocessing_id ? 'DC-'+data?.ticket?.assignorderprocessing_id : ''}</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            Projekt {' '}
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              <NavLink to={'/projects/' + data?.project_nr} className="text-primary">{data?.project_nr}</NavLink>
                            </SoftTypography>{" "}
                            vom{" "}
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              23.02.2021
                            </SoftTypography>
                            &nbsp;&nbsp;&nbsp;
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            Kunde:&nbsp;&nbsp;&nbsp;
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              {data?.project?.vorname + ' ' + data?.project?.name}
                            </SoftTypography>
                          </SoftTypography>
                        </SoftBox>
                        <SoftTypography variant="caption" color="text" mt={2}>
                          Start Datum:&nbsp;&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="medium">
                            {dateFormat(data && data?.start_date, "dd.mm.yyyy")}
                          </SoftTypography>
                        </SoftTypography>
                        <SoftTypography variant="caption" color="text">
                          End Datum:&nbsp;&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="medium">
                            {dateFormat(data && data?.end_date, "dd.mm.yyyy")}
                          </SoftTypography>
                        </SoftTypography>

                        <SoftBox mb={1} mt={3}>
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            Status Information
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              Erteilt von &nbsp;
                              <span style={{ color: "#0048B1" }}>
                                {userrec && userrec.name}
                              </span>
                            </SoftTypography>
                          </SoftTypography>
                        </SoftBox>

                        <Timeline refresh={refresh} projectid={(props?.viewRelamation?.projectId ? props?.viewRelamation?.projectId : (data?.project_nr ? data?.project_nr : ''))} />
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={3} lg={2}>
          {" "}
        </Grid>
      </Grid>


      {/* checklist modal */}
      <button type="button" class="btn bg-gradient-primary d-none" data-bs-toggle="modal" id="createCheckListModalOpen" data-bs-target="#checklistModal"></button>
      <button type="button" class="btn bg-gradient-primary d-none" data-bs-toggle="modal" id="editReclamationModalOpen" data-bs-target="#exampleModalEdit"></button>
      <CheckListManageModal refresh={setRefresh} checkLists={checkLists} id={(props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID)} />
      <Edit refresh={setRefresh} id={(props?.viewRelamation?.id ? props?.viewRelamation?.id : params.rID)} />

      {renderdisplayMessage}
    </>
  );
}

export default Show;
