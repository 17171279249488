import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Navigate, NavLink, useNavigate, withRouter } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

// Data
import Cookies from 'js-cookie';
import callFetch from "../../helpers/callFetch";
import DashboardCalendar from 'pages/dashboard/Calendar';

function Index(props) {
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [byDefaultSelectedTeams, setByDefaultSelectedTeams] = useState(null);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [projectDisplay, setProjectDisplay] = useState(true);

  const [isUpdate, setIsUpdate] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {

    callFetch("my-teams?team_type=" + (props?.team_type ? props?.team_type : 'sales,ac,dc'), "GET", []).then((res) => {
      setByDefaultSelectedTeams(res.selected_teams)
      if (res.selected_team_members != null) {
        setSelectedTeamMembers(res.selected_team_members)
      }
      setTeams(res.data);
    });
  }, [isUpdate]);

  useEffect(() => {
    if (!teams.length) return;

    if (byDefaultSelectedTeams == null) {
      var vals = [];
      for (let [key, team_value] of Object.entries(teams)) {
        vals.push(parseInt(team_value.id));
      }
    } else {
      var vals = JSON.parse(byDefaultSelectedTeams);
    }

    filteredCalendarData(vals);
  }, [teams]);

  const filteredCalendarData = (teamIds = '', mobile_teams = '') => {
    if (teamIds) {
      var vals = teamIds;
    } else {
      if (mobile_teams == '') {
        var input_teams = document.getElementsByName('teams[]');
      } else {
        var input_teams = document.getElementsByName('mobile_teams[]');
      }
      var vals = [];
      for (var i = 0, n = input_teams.length; i < n; i++) {
        if (input_teams[i].checked) {
          vals.push(parseInt(input_teams[i].value));
        }
      }
    }

    setSelectedTeams(vals);
    setProjectDisplay(props?.vkEventHide == true ? false : true);
    setEditEvent(props?.editEvent == true ? true : false);
    setReadyToLoad(true);
  }

  return (
    <>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <div className="row">
            <div className="col-md-12">
              <Grid container spacing={0}>
                <Grid item xs={12} lg={3} xl={3} className="d-lg-none">
                  <SoftBox mt={3} style={{ backgroundColor: '#EEEEEE', paddingLeft: '15px', paddingTop: '12px', borderRadius: '10px' }}>
                    <h5 className="h5cs" style={{ borderBottom: '1px solid #ddd', paddingBottom: '5px', color: '#6A6A6A', fontSize: '1.125rem !important', fontWeight: '700 !important' }}>&nbsp;Teams</h5>
                    <div className="App2" style={{ paddingTop: '10px' }}>
                      {teams ? teams.map((team) => (
                        <>
                          <SoftBox className="mb-2" display="flex" alignItems="center">
                            <Checkbox
                              value={team.id}
                              name="mobile_teams[]"
                              defaultChecked={false}
                              checked={selectedTeams.indexOf(team.id) !== -1 ? true : false}
                              /*
                              onChange={(e) => {
                                filteredCalendarData('', 'mobile_teams');
                              }}
                              */
                              onChange={(e) => {

                                if (team?.team_members) {
                                  var selectedAllTeamMember = selectedTeamMembers;
                                  var members = selectedAllTeamMember;

                                  Object.keys(team?.team_members).map(function (key) {
                                    if (selectedTeamMembers.indexOf(parseInt(team.team_members[key].value)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.team_members[key].value));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.team_members[key].value)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.team_members[key].value))
                                    }
                                  });

                                  if (selectedTeamMembers.indexOf(parseInt(team?.manager?.id)) == -1 && e.target.checked) {
                                    members.push(parseInt(team?.manager?.id));
                                  } else if (selectedTeamMembers.indexOf(parseInt(team?.manager?.id)) !== -1 && e.target.checked == false) {
                                    members = members.filter(e => e !== parseInt(team?.manager?.id))
                                  }

                                  setSelectedTeamMembers(members);
                                  setRefresh(refresh * (Math.random() * 10) * 10)
                                }

                                filteredCalendarData('', 'mobile_teams');
                              }}
                            />
                            <SoftBox mr={0.7} lineHeight={1}>
                              <SoftTypography variant="button" fontWeight="medium">{team.team_name}</SoftTypography>
                              <i
                                className="fc-icon fc-icon-plus-square ps-2"
                                onClick={(e) => {
                                  if (e.target.getAttribute("data-members") == 'show') {
                                    e.target.setAttribute("data-members", 'hide');
                                    document.getElementById("teamMembers" + team.id).classList.add("d-none");
                                  } else {
                                    e.target.setAttribute("data-members", 'show');
                                    document.getElementById("teamMembers" + team.id).classList.remove("d-none");
                                  }
                                }}
                                data-members="show"
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </SoftBox>
                          </SoftBox>
                          <div className="team-members ps-4" id={'teamMembers' + team.id}>

                            <SoftBox className="mb-2" display="flex" alignItems="center">
                              <Checkbox
                                value={team?.manager?.id}
                                name="team_members[]"
                                //defaultChecked={false}
                                checked={(selectedTeamMembers.indexOf(parseInt(team?.manager?.id)) !== -1 ? true : false)}
                                onChange={(e) => {

                                  var selectedAllTeamMember = selectedTeamMembers;
                                  var members = selectedAllTeamMember;

                                  if (selectedTeamMembers.indexOf(parseInt(team?.manager?.id)) == -1 && e.target.checked) {
                                    members.push(parseInt(e.target.value));
                                  } else {
                                    members = members.filter(e => e !== parseInt(team?.manager?.id))
                                  }
                                  setSelectedTeamMembers(members);

                                  setRefresh(refresh * (Math.random() * 10) * 10)
                                }}
                              />
                              <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{team?.manager?.name}</SoftTypography></SoftBox>
                            </SoftBox>

                            {team?.team_members ? Object.keys(team?.team_members).map((key) => (
                              <>
                                {team.team_members[key].label ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={team.team_members[key].value}
                                      name="team_members[]"
                                      //defaultChecked={false}
                                      checked={(selectedTeamMembers.indexOf(parseInt(team.team_members[key].value)) !== -1 ? true : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(team.team_members[key].value)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(team.team_members[key].value))
                                        }
                                        setSelectedTeamMembers(members);

                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{team.team_members[key].label}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : <></>}
                          </div>
                        </>
                      )) : <></>}
                    </div>
                  </SoftBox>
                </Grid>

                <Grid item xs={12} lg={10} xl={10}>
                  <DashboardCalendar
                    teamsEvent={true}
                    teams={selectedTeams}
                    teamMembers={selectedTeamMembers}
                    readyToLoad={readyToLoad}
                    projects={projectDisplay}
                    assign_orders={true}
                    reclamations={true}
                    absence={false}
                    editEvent={editEvent}
                    refresh={refresh}
                  />
                </Grid>

                <Grid item xs={12} lg={2} xl={2} className="d-none d-lg-block">
                  <SoftBox style={{ backgroundColor: '#EEEEEE', height: '100%', paddingLeft: '15px', paddingTop: '12px', borderRadius: '0px 10px 10px 0px' }}>
                    <h5 className="h5cs" style={{ borderBottom: '1px solid #ddd', paddingBottom: '5px', color: '#6A6A6A', fontSize: '1.125rem !important', fontWeight: '700 !important' }}>&nbsp;Teams</h5>
                    <div className="App2 team-checkboxs" style={{ paddingTop: '10px', overflowY: 'auto', maxHeight: '1015px' }}>
                      {teams ? teams.map((team) => (
                        <>
                          <SoftBox className="mb-2" display="flex" alignItems="center">
                            <Checkbox
                              value={team.id}
                              name="teams[]"
                              defaultChecked={false}
                              checked={selectedTeams.indexOf(team.id) !== -1 ? true : false}
                              onChange={(e) => {

                                 

                                if (team?.teammember) {
                                  var selectedAllTeamMember = selectedTeamMembers;
                                  var members = selectedAllTeamMember;

                                  Object.keys(team?.teammember).map(function (key) {
                                    if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.teammember[key]?.employe?.id));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.teammember[key]?.employe?.id)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.teammember[key]?.employe?.id))
                                    }
                                  });

                                  Object.keys(team?.manager).map(function (key) {
                                    console.log('check all')
                                    console.log(team.manager[key]?.user?.id)

                                    if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) == -1 && e.target.checked) {
                                      members.push(parseInt(team.manager[key]?.employe?.id));
                                    } else if (selectedTeamMembers.indexOf(parseInt(team.manager[key]?.user?.id)) !== -1 && e.target.checked == false) {
                                      members = members.filter(e => e !== parseInt(team.manager[key]?.user?.id))
                                    }
                                  });

                                  
                                  setSelectedTeamMembers(members);
                                  setRefresh(refresh * (Math.random() * 10) * 10)
                                }

                                filteredCalendarData();
                              }}
                            />
                            <SoftBox mr={0.7} lineHeight={1}>
                              <SoftTypography variant="button" fontWeight="medium">{team.team_name}</SoftTypography>
                              <i
                                className="fc-icon fc-icon-plus-square ps-2"
                                onClick={(e) => {
                                  if (e.target.getAttribute("data-members") == 'show') {
                                    e.target.setAttribute("data-members", 'hide');
                                    document.getElementById("teamMembers" + team.id).classList.add("d-none");
                                  } else {
                                    e.target.setAttribute("data-members", 'show');
                                    document.getElementById("teamMembers" + team.id).classList.remove("d-none");
                                  }
                                }}
                                data-members="show"
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </SoftBox>
                          </SoftBox>
                          <div className="team-members ps-4" id={'teamMembers' + team.id}>

                          {team?.manager ? team?.manager?.map((item) => (
                              <>
                                {item?.user?.name ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={item?.user?.id}
                                      name="team_members[]"
                                      checked={(selectedTeamMembers.indexOf(parseInt(item?.user?.id)) !== -1 ? true : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(item?.user?.id)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(item?.user?.id))
                                        }
                                        setSelectedTeamMembers(members);
                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{item?.user?.name}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : ''}              

                            {team?.teammember ? team?.teammember?.map((item) => (
                              <>
                                {item?.employe?.name ? (
                                  <SoftBox className="mb-2" display="flex" alignItems="center">
                                    <Checkbox
                                      value={item?.employe?.id}
                                      name="team_members[]"
                                      checked={(selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) !== -1 ? true : false)}
                                      onChange={(e) => {

                                        var selectedAllTeamMember = selectedTeamMembers;
                                        var members = selectedAllTeamMember;

                                        if (selectedTeamMembers.indexOf(parseInt(item?.employe?.id)) == -1 && e.target.checked) {
                                          members.push(parseInt(e.target.value));
                                        } else {
                                          members = members.filter(e => e !== parseInt(item?.employe?.id))
                                        }
                                        setSelectedTeamMembers(members);
                                        setRefresh(refresh * (Math.random() * 10) * 10)
                                      }}
                                    />
                                    <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{item?.employe?.name}</SoftTypography></SoftBox>
                                  </SoftBox>
                                ) : ''}
                              </>
                            )) : ''}
                          </div>
                        </>
                      )) : <></>}
                    </div>
                  </SoftBox>
                </Grid>
              </Grid>
            </div>
          </div>
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default Index;
